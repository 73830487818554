import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Avatar,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import { Button, Tooltip, Snackbar, Alert } from '@mui/material'
import moment from 'moment'
import cn from 'classnames'
import ButtonComponent from './Button'
import FormDialog from './FormDialog'
import { useTranslation } from 'react-i18next'
import { BiTransfer } from 'react-icons/bi'

const headCells = [
  {
    id: 'avatar',
    numeric: true,
    disablePadding: true,
    label: 'account_management.linked_account.avatar',
  },
  {
    id: 'accountName',
    numeric: true,
    disablePadding: true,
    label: 'account_management.linked_account.name',
  },
  {
    id: 'sdt',
    numeric: true,
    disablePadding: true,
    label: 'account_management.linked_account.phone',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: true,
    label: 'account_management.linked_account.email',
  },
  {
    id: 'lastTime',
    numeric: true,
    disablePadding: true,
    label: 'account_management.linked_account.last_logged_in',
  },
  {
    id: 'account_type',
    numeric: true,
    disablePadding: true,
    label: 'account_management.linked_account.type',
  },
  {
    id: 'action',
    numeric: true,
    disablePadding: true,
    label: 'account_management.linked_account.action',
  },
]

function EnhancedTableHead(props) {
  const { t } = useTranslation()
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            <TableSortLabel hideSortIcon={true}>
              {t(headCell.label)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '15px 15px 15px 0px',
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  noData: {
    color: 'rgba(0,0,0,.5)',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingBottom: 15,
  },
  buttonIEA: {
    width: '100%',
    textAlign: 'right',
    marginRight: '15px',
    paddingRight: '15px',
    '& button': {
      marginLeft: 15,
    },
  },
  avatar: {
    margin: '0px auto',
  },
  titlePopup: {
    textAlign: 'center',
    color: '#05AE34',
  },
  wrapButtonPopup: {
    textAlign: 'center',
  },
}))

export default function ListHistoryjs(props) {
  const {
    data,
    handleInviteCode,
    handleUnlink,
    messageUnlink,
    statusUnlink,
    messageInviteError,
    user,
    handleOpenTransferConfirm,
    setTransferedId,
    handleOpenTransfer,
  } = props
  const rows = (data && data.users) || []
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [dense, setDense] = useState(true)
  const [unlink, setUnlink] = useState({})
  const [isUnlink, setIsUnlink] = useState(false)
  const [showStatus, setShowStatus] = useState({
    open: false,
    type: 'success',
    message: '',
  })
  const { t } = useTranslation()

  useEffect(() => {
    if (statusUnlink) {
      setIsUnlink(false)
      setShowStatus({
        open: true,
        type: 'success',
        message: t('account_management.linked_account.unlink_success'),
      })
    } else if (messageUnlink.message) {
      setShowStatus({
        open: true,
        type: 'error',
        message: t('update.error_unlink'),
      })
    }
  }, [messageUnlink, statusUnlink, messageInviteError])

  const handleChangeDense = (event) => {
    setDense(event.target.checked)
  }

  const handleSubmitInviteCode = () => {
    handleInviteCode()
  }

  const handleSubmitUnlink = (row) => {
    setIsUnlink(true)
    setUnlink(row)
  }

  const handleConfirmUnlink = () => {
    handleUnlink(unlink)
  }

  const handleCloseUnlink = () => {
    setIsUnlink(false)
  }

  const handleCloseStatus = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowStatus((prev) => ({
      ...prev,
      open: false,
    }))
  }

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead classes={classes} rowCount={rows.length} />
              <TableBody>
                {(rows || []).map((row, index) => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={index}>
                      <TableCell align="right">
                        {row.avatar ? (
                          <Avatar
                            className={classes.avatar}
                            alt={row.name}
                            src={row.avatar}
                          />
                        ) : (
                          <Avatar className={classes.avatar} alt={row.name} />
                        )}
                      </TableCell>
                      <TableCell align="right">{row.name}</TableCell>
                      <TableCell align="right">{row.phone}</TableCell>
                      <TableCell align="right">{row.email}</TableCell>
                      <TableCell align="right">
                        {moment(row.lastLogin).format("DD/MM/YYYY HH:mm")}
                      </TableCell>
                      <TableCell align="right">
                        {row.usedWithUserId
                          ? t("overall.account_info.linked_account")
                          : t("overall.account_info.main_account")}
                      </TableCell>
                      <TableCell
                        align="right"
                        className="flex flex-row justify-center items-center gap-2"
                      >
                        {row.usedWithUserId ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "8px",
                              minHeight: "32px"
                            }}
                          >
                            <ButtonComponent
                              id="disconnect"
                              title={t("common.btn.unlink")}
                              className="btn--rounder"
                              backgroundColor="#BDBDBD"
                              color="white"
                              style={{
                                height: "32px",
                                padding: "6px 16px",
                                minWidth: "100px"
                              }}
                              handleClickButton={() => handleSubmitUnlink(row)}
                            />

                            {!user.usedWithUserId && (
                              <ButtonComponent
                                id="transfer"
                                title={
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      height: "100%"
                                    }}
                                  >
                                    <BiTransfer size={18} />
                                  </div>
                                }
                                className="btn--rounder"
                                backgroundColor="#05ae34"
                                color="white"
                                style={{
                                  height: "32px",
                                  padding: "6px 16px",
                                  minWidth: "40px" // Width nhỏ hơn cho button icon
                                }}
                                handleClickButton={() => {
                                  setTransferedId(row.id);
                                  handleOpenTransferConfirm(true);
                                }}
                              />
                            )}
                          </div>
                        ) : (
                          <Tooltip
                            title={t(
                              "account_management.linked_account.main_account_tooltip"
                            )}
                            placement="top"
                          >
                            <span>
                              <ButtonComponent
                                id="disconnect"
                                title={t("common.btn.unlink")}
                                className="btn--rounder"
                                backgroundColor="#BDBDBD"
                                color="white"
                                disabled={true}
                                style={{
                                  opacity: 0.6,
                                  cursor: "not-allowed"
                                }}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length === 0 && (
            <p className="py-4 text-muted text-center">{t("update.no_data")}</p>
          )}
        </Paper>
      </div>

      <div className={classes.buttonIEA}>
        <Tooltip
          title={user.usedWithUserId ? t("common.btn.add_account_warning") : ""}
          placement="top"
        >
          <span>
            <Button
              disabled={user.usedWithUserId}
              onClick={handleSubmitInviteCode}
            >
              {t("common.btn.add_account")}
            </Button>
          </span>
        </Tooltip>

        <Button
          disabled={true}
          // disabled={user.usedWithUserId}
          onClick={handleOpenTransfer}
        >
          {t("account_management.service_information.transfer_main_account")}
        </Button>
      </div>

      <FormDialog isOpen={isUnlink}>
        <div className={cn(classes.wrapChangePassword, "p-4")}>
          <p style={{ textAlign: "center" }} className="mb-2 fz-15">
            {t("account_management.linked_account.unlink")}
          </p>
          <div className="text-center mt-4">
            <ButtonComponent
              className="mr-2"
              title={t("common.btn.cancel")}
              backgroundColor="#BDBDBD"
              color="white"
              handleClickButton={handleCloseUnlink}
            />
            <ButtonComponent
              title={t("common.btn.confirm")}
              backgroundColor="#05AE34"
              color="white"
              handleClickButton={handleConfirmUnlink}
            />
          </div>
        </div>
      </FormDialog>

      <Snackbar
        open={showStatus.open}
        autoHideDuration={3000}
        onClose={handleCloseStatus}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseStatus}
          severity={showStatus.type}
          sx={{ width: "100%" }}
        >
          {showStatus.message}
        </Alert>
      </Snackbar>
    </>
  );
}
