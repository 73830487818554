import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core'

const useStyles = makeStyles({
  container: (props) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: props.backgroundColor,
    borderRadius: '22px',
    border: `1px solid ${props.textColor}20`, // Thêm border với màu tương tự như textColor
    maxWidth: props.maxWidth,
    padding: props.paddingCon,
    width: 'fit-content',
    position: 'relative', // Thêm position để xử lý overlay
    cursor: 'pointer',
  }),
  text: (props) => ({
    fontSize: props.fontSize, // Sử dụng fontSize từ props
    color: props.textColor,
    padding: props.padding, // Sử dụng padding từ props
  }),
  icon: (props) => ({
    fontSize: props.iconSize, // Sử dụng iconSize từ props
    color: '#3A3A3A',
  }),

  removedOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 0, 0, 0.5)', // overlay màu đỏ
    borderRadius: '20px',
    pointerEvents: 'none', // Để overlay không cản trở các thao tác
    backgroundImage:
      'repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px)', // Thêm sọc chéo
    zIndex: 1, // Đảm bảo overlay ở trên cùng
  },
})

const TagPreviewItem = ({
  background,
  color,
  name,
  size = 'medium',
  style,
  styleText,
  onRemove,
  onUnRemove,
}) => {
  const sizeStyles = {
    small: {
      padding: '2px 6px',
      fontSize: '11px !important',
      iconSize: '16px',
      maxWidth: '200px',
    },
    medium: {
      padding: '4px 8px',
      fontSize: '13px !important',
      iconSize: '20px',
      maxWidth: '220px',
    },
    large: {
      padding: '6px 2px 6px 12px',
      fontSize: '16px !important',
      iconSize: '24px',
    },
  }

  const [isRemoved, setIsRemoved] = useState(false)

  const selectedSize = sizeStyles[size] || sizeStyles.medium // Mặc định là medium nếu không có size hợp lệ

  const classes = useStyles({
    backgroundColor: background,
    textColor: color,
    padding: selectedSize.padding,
    fontSize: selectedSize.fontSize,
    iconSize: selectedSize.iconSize,
    paddingCon: size !== 'small' ? '2px' : '0px',
  })

  const handleDelete = () => {
    setIsRemoved(true)
  }

  return (
    <Tooltip
      title={
        <Typography
          style={{
            fontSize: size === 'small' ? '10px' : '12px',
          }}
        >
          {name}
        </Typography>
      }
    >
      <Box
        style={style}
        className={classes.container}
        onClick={() => {
          onUnRemove()
          setIsRemoved(false)
        }}
      >
        <Typography
          noWrap={false}
          className={classes.text}
          style={{
            whiteSpace: 'normal',
            wordBreak: 'break-all',
            ...styleText,
          }}
        >
          {name}
        </Typography>
        {onRemove && (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              onRemove()
              handleDelete()
            }}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        )}
        {isRemoved && <div className={classes.removedOverlay}></div>}
      </Box>
    </Tooltip>
  )
}

export default TagPreviewItem
