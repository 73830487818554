import { createSlice } from "@reduxjs/toolkit";
import initialState from "../../configs/redux/initialState";

const settingAccountReducer = createSlice({
  name: "settingAccountReducer",
  initialState: initialState.settingAccountReducer,
  reducers: {
    whatYouNeed: (state, action) => {
      state.whatYouNeed = action.payload;
    },
    changePassword: (state, action) => {
      state.changePassword = action.payload;
    },
    changePasswordStatus: (state, action) => {
      state.changePassword = action.payload;
    },
    setInviteCode: (state, action) => {
      state.inviteCode = action.payload;
    },
    setUnlink: (state, action) => {
      state.unlink = action.payload;
    },
    setMessageUnlink: (state, action) => {
      state.messageUnlink = action.payload;
    },
    setMessageInviteError: (state, action) => {
      // console.log('action', action)
      state.messageInviteError = action.payload.message;
    },
    
  }
});



export const {
    whatYouNeed,
    changePassword,
    changePasswordStatus,
    setInviteCode,
    setUnlink,
    setMessageUnlink,
    setMessageInviteError,
} = settingAccountReducer.actions;

export default settingAccountReducer.reducer;
