import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
export const customer = (state) => state.customerReducer.customer
export const edit = (state) => state.customerReducer.edit
export const detail = (state) => state.customerReducer.detail
export const fanpageSelected = (state) => state.customerReducer.fanpageSelected
export const tagFacebookSelectedAll = (state) =>
  state.customerReducer.tagFacebookSelectedAll
export const fanpageFull = (state) => state.fanpageReducer.fanpageFull
export const loadingImport = (state) => state.customerReducer.loadingImport
export const importSuccess = (state) => state.customerReducer.importSuccess
export const fanPageDetail = (state) => state.customerReducer.fanPageDetail
export const customerAll = (state) => state.customerReducer.customerAll
export const customerCheck = (state) => state.customerReducer.customerCheck
export const selectImportResult = (state) => state.customerReducer.importResult
export const createCustomerSucss = (state) =>
  state.customerReducer.createCustomerSucss
export const messageImport = (state) => state.customerReducer.messageImport

export const getCustomerAll = createSelector([customerAll], (customerAll) => {
  const items = []
  if (customerAll && customerAll.length > 0) {
    customerAll.map((item) => {
      items.push({
        ...item,
        checked: true,
      })
    })
  }
  return items
})

export const getCustomer = createSelector(
  [customer, customerCheck],
  (customer, customerCheck) => {
    const items = []
    if (customer && customer.content && customer.content.length > 0) {
      ;(customer.content || []).map((item) => {
        let obj = _.find(customerCheck, function (obj) {
          return obj.id == item.id
        })
        if (obj) {
          items.push({
            ...item,
            checked: false,
          })
        } else {
          items.push({
            ...item,
            checked: true,
          })
        }
      })
    }
    return { ...customer, content: items }
  }
)

export const getListTagFacebook = createSelector(
  [fanpageSelected, detail],
  (fanpageSelectedData, detail) => {
    const items = []
    if (
      fanpageSelectedData &&
      fanpageSelectedData.fbTags &&
      fanpageSelectedData.fbTags.length > 0
    ) {
      let data
      data = [
        {
          id: 'not_in_tag',
          name: 'Không có tag',
          color: '999',
        },
        ...fanpageSelectedData.fbTags,
      ]
      ;(data || []).map((item) => {
        let obj = _.find(detail.fbTags, function (obj) {
          return obj == item.id
        })
        if (obj) {
          items.push({
            ...item,
            checked: true,
          })
        } else {
          items.push({
            ...item,
            checked: false,
          })
        }
      })
    }

    return items
  }
)

export const getListTagBombot = createSelector(
  [fanpageSelected, detail, fanPageDetail],
  (fanpageSelectedData, detail, fanPageDetail) => {
    const items = []
    if (
      fanpageSelectedData &&
      fanpageSelectedData.bombotTags &&
      fanpageSelectedData.bombotTags.length > 0
    ) {
      let data
      if (
        fanPageDetail &&
        fanPageDetail.bombotTags &&
        fanPageDetail.bombotTags.length > 0
      ) {
        data = [
          {
            id: 'not_in_tag',
            name: 'Không có tag',
            color: '999',
          },
          ...fanPageDetail.bombotTags,
        ]
      } else {
        data = [
          {
            id: 'not_in_tag',
            name: 'Không có tag',
            color: '999',
          },
          ...fanpageSelectedData.bombotTags,
        ]
      }
      ;(data || []).map((item) => {
        let obj = _.find(detail.bombotTags, function (obj) {
          return obj == item.id
        })
        if (obj) {
          items.push({
            ...item,
            checked: true,
          })
        } else {
          items.push({
            ...item,
            checked: false,
          })
        }
      })
    }
    return items
  }
)

export const getDetail = createSelector([detail], (data) => {
  let fbTags = []
  let bombotTags = []
  if (data && data.fbTags && data.fbTags.length > 0) {
    ;(data.fbTags || []).map((item) => {
      fbTags.push({
        item,
        checked: true,
      })
    })
  }
  if (data && data.bombotTags && data.bombotTags.length > 0) {
    ;(data.bombotTags || []).map((item) => {
      bombotTags.push({
        item,
        checked: true,
      })
    })
  }

  return { ...data, fbTags: fbTags, bombotTags: bombotTags }
})

//////// tuan anh code

const selectCustomer = (state) => state.customerReducer

export const selectFilterCus = createSelector(
  selectCustomer,
  (state) => state.filter
)

export const selectSearchUidName = createSelector(
  selectCustomer,
  (state) => state.searchUidName
)

export const selectFanpageSelected = createSelector(
  selectCustomer,
  (state) => state.fanpageSelected
)

export const selectBombotTags = createSelector(
  selectCustomer,
  (state) => state.fanpageSelected?.bombotTags || []
)

export const selectAddTagLoading = createSelector(
  selectCustomer,
  (state) => state.loading_add_tag
)
export const selectIsShowModal = createSelector(
  selectCustomer,
  (state) => state.is_show_modal_add
)

export const selectTagSelected = createSelector(
  selectCustomer,
  (state) => state.tagsSelected
)

export const selectTagsPageSelected = createSelector(
  fanpageSelected,
  (state) => {
    const fbTags = state?.fbTags
    const bombotTags = state?.bombotTags

    const fbTagsObj = {}
    const bombotTagsObj = {}

    if (fbTags) {
      fbTags.forEach((tag) => (fbTagsObj[tag.id] = tag))
    }
    if (bombotTags) {
      bombotTags.forEach((tag) => (bombotTagsObj[tag.id] = tag))
    }

    return { fbTags: fbTagsObj, bombotTags: bombotTagsObj }
  }
)
