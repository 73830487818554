import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import appApi from 'api/appApi'
i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    cache: {
      enabled: false,
    },
  })
  .then(async () => {
    try {
      const uid = localStorage.getItem('id')
      if (!uid) throw new Error('new client')
      const user = await appApi.getUser(uid)
      if (!user.lang) {
        if (!uid) throw new Error('new client')
      }
      i18n.changeLanguage(user.lang)
    } catch (error) {
      // i18n.changeLanguage("vi");
    }
  })

export default i18n
