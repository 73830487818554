import Flex from 'components/Flex'
import BaseSelect from 'components/Form/BaseSelect'
import {
  FIELD_OPTIONS,
  GENDER_OPTION,
  GENDER_OPTIONS,
  OPERATION_OPTION,
  OPERATION_OPTION_NAME,
  OPERATION_PHONE_OPTION,
  OPERATION_TAGS_OPTION,
  OPERATION_TIME_OPTION,
  PHONE_OPTIONS,
} from 'constants/helper'
import React, { useEffect, useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import BaseInput from 'components/Form/BaseInput'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import BaseAutocomplete from 'components/Form/BaseAutocomplete'
import BaseDatePicker from 'components/Form/BaseDatePicker'
import { useSelector } from 'react-redux'
import { Chip, IconButton } from '@material-ui/core'
import { selectFanpageSelected } from 'views/Customer/CustomerSelectors'
import useEffectIgnoreFirst from 'hook/useEffectIgnoreFirst'
import { useTranslation } from 'react-i18next'

const RenderValue = ({ fieldSelected, name, control, defaultValue }) => {
  const pageSelected = useSelector(selectFanpageSelected)
  const { t } = useTranslation()
  const selectOptions = useMemo(() => {
    if (!pageSelected || !pageSelected.bombotTags) return []
    switch (fieldSelected) {
      case 'bombotTags':
        return pageSelected.bombotTags?.map((t) => ({
          value: t?.id,
          label: t?.name,
          color: t?.color,
        }))
      case 'fbTags':
        if (!pageSelected.fbTags) {
          return []
        }
        return pageSelected.fbTags?.map((t) => ({
          value: t?.id,
          label: t?.name,
          color: t?.color,
        }))
      case 'gender':
        return GENDER_OPTIONS?.map((e) => ({ ...e, label: t(e.label) }))
      case 'phone':
        return PHONE_OPTIONS?.map((e) => ({ ...e, label: t(e.label) }))
      default:
        return []
    }
  }, [fieldSelected, pageSelected])

  switch (fieldSelected) {
    case 'uid':
    case 'name':
      return (
        <BaseInput
          disabled={!fieldSelected}
          name={name}
          control={control}
          className="w-100"
          defaultValue={defaultValue}
        />
      )
    case 'gender':
    case 'phone':
      return (
        <BaseSelect
          disabled={!fieldSelected}
          name={name}
          control={control}
          style={{ width: 500 }}
          options={selectOptions}
          defaultValue={defaultValue}
        />
      )
    case 'bombotTags':
    case 'fbTags':
      return (
        <BaseAutocomplete
          inputStyle={{ width: '100%' }}
          defaultValue={defaultValue || []}
          renderTags={(value, getTagProps) => {
            return value?.map((option, index) => (
              <Chip
                d
                variant="outlined"
                label={option?.label || ''}
                {...getTagProps({ index })}
                style={{ backgroundColor: `#${option?.color}` }}
              />
            ))
          }}
          filterSelectedOptions
          disabled={!fieldSelected}
          name={name}
          control={control}
          options={selectOptions}
        />
      )
    case 'lastInboxTime':
      return (
        <Flex>
          <BaseDatePicker
            name={name}
            control={control}
            defaultValue={defaultValue || [null, null]}
          />
        </Flex>
      )
    case 'lastCustomerInboxTime':
      return (
        <Flex>
          <BaseDatePicker
            name={name}
            control={control}
            defaultValue={defaultValue || [null, null]}
          />
        </Flex>
      )
    case 'lastSyncTime':
      return (
        <Flex>
          <BaseDatePicker
            name={name}
            control={control}
            defaultValue={defaultValue || [null, null]}
          />
        </Flex>
      )

    default: {
      return (
        <BaseInput
          disabled={!fieldSelected}
          name={name}
          control={control}
          className="w-100"
        />
      )
    }
  }
}

const RenderOperation = ({
  fieldSelected,
  name,
  control,
  item,
  selectedFields,
}) => {
  const { t } = useTranslation()

  const OPTIONS = useMemo(() => {
    switch (fieldSelected) {
      case 'bombotTags':
      case 'fbTags':
        return OPERATION_TAGS_OPTION?.map((e) => ({ ...e, label: t(e.label) }))
      case 'lastInboxTime':
        return OPERATION_TIME_OPTION?.map((e) => ({ ...e, label: t(e.label) }))
      case 'lastSyncTime':
        return OPERATION_TIME_OPTION?.map((e) => ({ ...e, label: t(e.label) }))
      case 'lastCustomerInboxTime':
        return OPERATION_TIME_OPTION?.map((e) => ({ ...e, label: t(e.label) }))
      case 'phone':
        return OPERATION_PHONE_OPTION?.map((e) => ({
          ...e,
          label: t(e.label),
        }))
      case 'name':
      case 'uid':
        return OPERATION_OPTION_NAME?.map((e) => ({ ...e, label: t(e.label) }))
      case 'gender':
        return GENDER_OPTION?.map((e) => ({ ...e, label: t(e.label) }))
      default:
        return OPERATION_OPTION?.map((e) => ({ ...e, label: t(e.label) }))
    }
  }, [fieldSelected])

  return (
    <BaseSelect
      name={name}
      control={control}
      options={OPTIONS}
      style={{ maxWidth: 150 }}
      defaultValue={item.operation}
    />
  )
}

const ConditionItem = ({
  name,
  control,
  item,
  remove,
  setValue,
  index,
  selectedFields,
}) => {
  const { t } = useTranslation()

  const fieldSelected = useWatch({
    control,
    name: `${name}.field`,
  })

  useEffectIgnoreFirst(() => {
    switch (fieldSelected) {
      case 'fieldSelected':
        setValue(`${name}.value`, [null, null])
        break
      case 'bombotTags':
      case 'fbTags':
        setValue(`${name}.value`, [])
        break
      default:
        setValue(`${name}.value`, '')
        break
    }
  }, [fieldSelected])

  return (
    <Flex align="center">
      <BaseSelect
        name={`${name}.field`}
        control={control}
        style={{ width: 200, flexBasic: 200 }}
        options={FIELD_OPTIONS?.map((e) => ({ ...e, label: t(e.label) }))}
        defaultValue={item.field}
      />
      <div className="mx-3">
        <RenderOperation
          name={`${name}.operation`}
          control={control}
          item={item}
          fieldSelected={fieldSelected}
          defaultValue={item.operation}
        />
      </div>
      <RenderValue
        control={control}
        name={`${name}.value`}
        fieldSelected={fieldSelected}
        defaultValue={item.value}
      />
      <Tooltip title={t('update.remove_condition')}>
        <IconButton
          aria-label="delete"
          className="ml-2"
          onClick={() => {
            remove(index)
          }}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </Flex>
  )
}

export default ConditionItem
