import React, { useEffect } from 'react'
import { Dialog, DialogTitle } from '@material-ui/core'
import { Button, DialogContent, DialogActions } from '@material-ui/core'
import Flex from 'components/Flex'
import { useFieldArray, useForm } from 'react-hook-form'
import ButtonComponent from 'components/Button'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { setFilter } from 'views/Customer/CustomerReducer'
import { useDispatch } from 'react-redux'
import ConditionItem from './ConditionItem'
import { useHistory } from 'react-router-dom'
import {
  selectFanpageSelected,
  selectFilterCus,
} from 'views/Customer/CustomerSelectors'
import { removeVNLang } from 'utils/helper'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: 0,
    transform: 'translate(-50%, -50%)',
  },
}
Modal.setAppElement('body')

const FilterCustomerModal = ({
  handleClose,
  isOpen,
  customerAll,
  onSubmitFilter,
  searchVal,
}) => {
  const pageSelected = useSelector(selectFanpageSelected)
  const filter_value = useSelector(selectFilterCus)
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      conditions: [
        {
          field: '',
          operation: '',
          value: '',
        },
      ],
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'conditions',
  })

  useEffect(() => {
    if (filter_value?.search_obj?.conditions?.length) {
      reset({
        conditions: filter_value?.search_obj?.conditions,
      })
    }
  }, [filter_value?.search_obj?.conditions])

  useEffect(() => {
    reset({
      conditions: [
        {
          field: '',
          operation: '',
          value: '',
        },
      ],
    })
  }, [pageSelected])

  const onSubmit = (values) => {
    const filtered = values.conditions.filter(
      (e) => e.value && !(Array.isArray(e.value) && e.value.length === 0)
    )
    let payload = ''
    let filterArr = []

    filtered.forEach((condition) => {
      if (condition.operation === '==' || condition.operation === '!=') {
        payload +=
          condition.field +
          condition.operation +
          encodeURIComponent(condition.value) +
          ';'
      } else {
        switch (condition.field) {
          case 'bombotTags':
          case 'fbTags':
            payload +=
              condition.field +
              '=' +
              condition.operation +
              '=' +
              condition.value?.map((v) => v.value).toString() +
              ';'
            break
          case 'phone':
          case 'gender':
            payload +=
              condition.field +
              '=' +
              condition.operation +
              '=' +
              encodeURIComponent(removeVNLang(condition.value)) +
              ';'
            break
          case 'uid':
          case 'name':
            payload +=
              condition.field +
              '=' +
              condition.operation +
              '=' +
              removeVNLang(condition.value) +
              ';'
            break
          case 'lastInboxTime':
            payload +=
              condition.field +
              '=' +
              condition.operation +
              '=' +
              condition.value
                .map((v) => moment(v).format('YYYY-MM-DD'))
                .toString() +
              ';'
            break
          case 'lastCustomerInboxTime':
            payload +=
              condition.field +
              '=' +
              condition.operation +
              '=' +
              condition.value
                .map((v) => moment(v).format('YYYY-MM-DD'))
                .toString() +
              ';'
            break
          case 'lastSyncTime':
            payload +=
              condition.field +
              '=' +
              condition.operation +
              '=' +
              condition.value
                .map((v) => moment(v).format('YYYY-MM-DD'))
                .toString() +
              ';'
            break
          default:
            break
        }
      }
    })

    if (filtered[0]) {
      if (filtered[0].field === 'uid') {
        if (filtered[0].operation === 'contain') {
          const customerFilter = customerAll.filter((cus) =>
            cus.uid.includes(filtered[0].value)
          )
          onSubmitFilter(customerFilter)
        } else {
          const customerFilter = customerAll.filter(
            (cus) => !cus.uid.includes(filtered[0].value)
          )
          onSubmitFilter(customerFilter)
        }
      }

      if (filtered[0].field === 'name') {
        if (filtered[0].operation === 'contain') {
          const customerFilter = customerAll.filter(
            (cus) => cus.name && cus.name.includes(filtered[0].value)
          )
          onSubmitFilter(customerFilter)
        } else {
          const customerFilter = customerAll.filter(
            (cus) => cus.name && !cus.name.includes(filtered[0].value)
          )
          onSubmitFilter(customerFilter)
        }
      }

      if (filtered[0].field === 'gender') {
        if (filtered[0].operation === '==') {
          if (filtered[0].value !== 'ALL') {
            const customerFilter = customerAll.filter(
              (cus) => cus.gender === filtered[0].value
            )
            onSubmitFilter(customerFilter)
          }
        } else {
          if (filtered[0].value !== 'ALL') {
            const customerFilter = customerAll.filter(
              (cus) => cus.gender !== filtered[0].value
            )
            onSubmitFilter(customerFilter)
          }
        }
      }

      if (filtered[0].field === 'phone') {
        if (filtered[0].operation === 'exists') {
          if (filtered[0].value === 'true') {
            const customerFilter = customerAll.filter((cus) => cus.phone)
            onSubmitFilter(customerFilter)
          }
        } else {
          if (filtered[0].value !== 'true') {
            const customerFilter = customerAll.filter((cus) => !cus.phone)
            onSubmitFilter(customerFilter)
          }
        }
      }

      if (filtered[0].field === 'fbTags') {
        if (filtered[0].operation === 'in') {
          if (filtered[0].value && filtered[0].value.length !== 0) {
            filtered[0].value.map((e) => filterArr.push(e.value))
            Array.from(new Set(filterArr))
            const customerFilter = customerAll.filter(
              (cus) => JSON.stringify(cus.fbTags) === JSON.stringify(filterArr)
            )
            onSubmitFilter(customerFilter)
          }
        } else {
          if (filtered[0].value && filtered[0].value.length !== 0) {
            filtered[0].value.map((e) => filterArr.push(e.value))
            Array.from(new Set(filterArr))
            const customerFilter = customerAll.filter(
              (cus) => JSON.stringify(cus.fbTags) !== JSON.stringify(filterArr)
            )
            onSubmitFilter(customerFilter)
          }
        }
      }

      if (filtered[0].field === 'bombotTags') {
        if (filtered[0].operation === 'in') {
          if (filtered[0].value && filtered[0].value.length !== 0) {
            filtered[0].value.map((e) => filterArr.push(e.value))
            Array.from(new Set(filterArr))
            const customerFilter = customerAll.filter(
              (cus) =>
                JSON.stringify(cus.bombotTags) === JSON.stringify(filterArr)
            )
            onSubmitFilter(customerFilter)
          }
        } else {
          if (filtered[0].value && filtered[0].value.length !== 0) {
            filtered[0].value.map((e) => filterArr.push(e.value))
            Array.from(new Set(filterArr))
            const customerFilter = customerAll.filter(
              (cus) =>
                JSON.stringify(cus.bombotTags) !== JSON.stringify(filterArr)
            )
            onSubmitFilter(customerFilter)
          }
        }
      }

      if (filtered[0].field === 'lastInboxTime') {
        if (filtered[0].operation === 'bw') {
          if (filtered[0].value) {
            if (filtered[0].value[0] && filtered[0].value[1]) {
              const customerFilter = customerAll.filter(
                (cus) =>
                  new Date(cus.lastInboxTime) >= filtered[0].value[0] &&
                  new Date(cus.lastInboxTime) <= filtered[0].value[1]
              )
              onSubmitFilter(customerFilter)
            } else if (filtered[0].value[0] && !filtered[0].value[1]) {
              const customerFilter = customerAll.filter(
                (cus) =>
                  new Date(cus.lastInboxTime) >= filtered[0].value[0] &&
                  new Date()
              )
              onSubmitFilter(customerFilter)
            } else if (!filtered[0].value[0] && filtered[0].value[1]) {
              const customerFilter = customerAll.filter((cus) => cus)
              onSubmitFilter(customerFilter)
            }
          }
        }
      }
    }

    payload += `pageId==${pageSelected.id}`

    dispatch(
      setFilter({
        search_arr: values.conditions,
        search_string: payload,
      })
    )

    handleClose()
    const searchParam = new URLSearchParams(window.location.search)
    searchParam.set('query', payload)
    history.push({
      pathname: window.location.pathname,
      search: '?' + searchParam.toString(),
    })
  }

  const addCondition = () => {
    append({
      field: '',
      operation: '',
      value: '',
    })
  }
  const { t } = useTranslation()
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      ariaHideApp={false}
      style={customStyles}
    >
      <DialogTitle>
        {t('customer_management.customer_filter.title')}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent
          className="modal_tag"
          style={{ width: 700, maxHeight: 400 }}
        >
          {fields.map((item, index) => (
            <div className="mb-3" key={item.id}>
              <ConditionItem
                index={index}
                remove={remove}
                setValue={setValue}
                selectedFields={fields}
                control={control}
                item={item}
                name={`conditions[${index}]`}
              />
            </div>
          ))}
          <ButtonComponent
            className="btn--rounder btn--primary mt-3"
            title={t('common.btn.add_condition')}
            backgroundColor="#05AE34"
            color="white"
            handleClickButton={addCondition}
          />
        </DialogContent>
        <DialogActions>
          <Flex align="center" justify="flex-end" className="mt-3">
            <Button
              onClick={handleClose}
              size="small"
              className="btn--rounder btn--primary"
            >
              {t('common.btn.cancel')}
            </Button>

            <Button
              size="small"
              // disabled={isLoading}
              type="submit"
              className="btn--rounder btn--primary--filled ml-2"
            >
              <Flex align="center">
                {/* {isLoading && <CircularProgress size={12} color="white" />} */}
                <p className="my-0 ml-2"> {t('common.btn.save')}</p>
              </Flex>
            </Button>
          </Flex>
        </DialogActions>
      </form>
    </Modal>
  )
}

export default FilterCustomerModal
