import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  confirmButton: {
    backgroundColor: "#05AE34",
    color: "white",
    borderRadius: "20px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#049c2f"
    }
  },
  dialogActions: {
    paddingRight: "16px",
    paddingBottom: "16px"
  }
});

/**
 * Custom confirm modal có thể tái sử dụng ở các trang khác
 */
const Confirm = ({
  open,
  title,
  content,
  onClose,
  onConfirm,
  confirmText = 'Xác nhận',
  cancelText = 'Hủy'
}) => {
  const classes = useStyles();
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      {title && (
        <DialogTitle>
          <Typography variant="h6">{title}</Typography>
        </DialogTitle>
      )}
      
      <DialogContent>
        <Typography>{content}</Typography>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} color="default">
          {cancelText}
        </Button>
        <Button 
          onClick={onConfirm}
          className={classes.confirmButton}
          variant="contained" 
          autoFocus
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;