import { useEffect } from 'react'

function useDebounce(callback, delay, dependencies = []) {
  useEffect(() => {
    const handler = setTimeout(() => {
      callback()
    }, delay)

    // Hủy bỏ timeout nếu dependencies thay đổi hoặc khi component unmount
    return () => clearTimeout(handler)
  }, [...dependencies, delay])
}

export default useDebounce
