import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import cn from "classnames";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "8px",
      minWidth: "500px"
    }
  },

  dialogTitle: {
    textAlign: "center",
    "& h2": {
      fontSize: "20px",
      fontWeight: 600,
      color: "#05AE34"
    },
    padding: "20px 24px 0px"
  },

  dialogContent: {
    padding: "20px 24px",
    "& .MuiTextField-root": {
      width: "100%"
    }
  },

  dialogActions: {
    padding: "12px 24px 24px",
    justifyContent: "flex-end",
    gap: "12px"
  },

  description: {
    fontSize: "14px",
    marginBottom: "20px",
    textAlign: "left"
  },

  inputWrapper: {
    marginBottom: "20px"
  },

  button: {
    borderRadius: "8px",
    padding: "6px 16px",
    textTransform: "none",
    fontWeight: 500,
    minWidth: "100px"
  },

  cancelButton: {
    backgroundColor: "#D1D5DB",
    color: "white",
    "&:hover": {
      backgroundColor: "#9CA3AF"
    }
  },

  submitButton: {
    backgroundColor: "#FF8000",
    color: "white",
    "&:hover": {
      backgroundColor: "#cc6600"
    },
    "&.Mui-disabled": {
      backgroundColor: "#FFB366",
      color: "white"
    }
  },

  error: {
    marginBottom: "16px"
  },

  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const TransferAcc = ({
  open,
  handleClose,
  handleSubmit,
  setTransferedId,
  transferedId
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const validateInput = (value) => {
    if (!value.trim()) {
      setError(t("account_management.transfer.error.required"));
      return false;
    }
    if (!/^[A-Za-z0-9]+$/.test(value)) {
      setError(t("account_management.service_information.error.invalid_format"));
      return false;
    }
    setError("");
    return true;
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setTransferedId(value);
    if (value) {
      validateInput(value);
    } else {
      setError("");
    }
  };

  const onSubmit = async () => {
    if (!validateInput(transferedId)) {
      return;
    }

    setLoading(true);
    try {
      await handleSubmit(transferedId);
      handleClose();
    } catch (err) {
      setError(err.message || t("common.error.unknown"));
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setError("");
    setTransferedId("");
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        {t("account_management.service_information.enter_id")}
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.description}>
          {t("account_management.service_information.description_transfer")}
        </Typography>

        {error && (
          <Alert severity="error" className={classes.error}>
            {error}
          </Alert>
        )}

        <div className={classes.inputWrapper}>
          <TextField
            autoFocus
            variant="outlined"
            size="small"
            placeholder={t("account_management.service_information.enter_id")}
            value={transferedId}
            onChange={handleChange}
            error={!!error}
            helperText={error}
            inputProps={{
              maxLength: 50
            }}
          />
        </div>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={onClose}
          className={cn(classes.button, classes.cancelButton)}
          disabled={loading}
        >
          {t("common.btn.cancel")}
        </Button>

        <Button
          onClick={onSubmit}
          className={cn(classes.button, classes.submitButton)}
          disabled={!transferedId.trim() || loading}
        >
          {loading ? (
            <CircularProgress size={24} className={classes.buttonProgress} />
          ) : (
            t("common.btn.confirm")
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferAcc;
