import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { pathKeys } from '../constants'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import cn from 'classnames'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import moment from 'moment'
import types from '../constants/types'
import ButtonComponent from './Button'
import Pagination from '@material-ui/lab/Pagination'
import Flex from './Flex'
import { Button, TextField } from '@material-ui/core'
import Tour from 'reactour'
import { getIsGuideByKey, GUIDE_TYPE_ENUM, setIsGuideByKey } from 'utils/helper'
import { useSelector } from 'react-redux'
import { selectGuideListByKey } from 'stores/config/config.selector'
import { useTranslation } from 'react-i18next'
const headCells = [
  {
    id: 'ord_number_Fanpage',
    numeric: true,
    disablePadding: true,
    label: 'fanpage_management.list.ord_number',
  },
  {
    id: 'nameFanpage',
    numeric: true,
    disablePadding: true,
    label: 'fanpage_management.list.page_name',
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'fanpage_management.list.page_id',
  },
  {
    id: 'time',
    numeric: true,
    disablePadding: true,
    label: 'fanpage_management.list.last_update_time',
  },
  {
    id: 'totalCus',
    numeric: true,
    disablePadding: true,
    label: 'fanpage_management.list.amount_customer',
  },
  // { id: "actions", numeric: true, disablePadding: true, label: "Thao tác" },
]

function EnhancedTableHead(props) {
  const { classes } = props
  const { t } = useTranslation()
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            style={{ width: '25%', whiteSpace: 'nowrap' }}
          >
            <TableSortLabel hideSortIcon={true}>
              {t(headCell.label)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '15px 15px 15px 0px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  '.pagination': {
    '& .MuiTablePagination-input': {
      width: 70,
    },
  },

  wrapperSearch: {
    width: '100%',
    position: 'relative',
  },
  buttonSearch: {
    width: 150,
    position: 'absolute',
    right: '250px',
  },
  noData: {
    color: 'rgba(0,0,0,.5)',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingBottom: 15,
  },
  buttonFilter: {
    width: 'max-content',
    marginLeft: 20,
  },
  nameFanpage: {
    cursor: 'pointer',
  },
}))

let timeout = 0

export default function EnhancedTable(props) {
  const { t } = useTranslation()

  const { data, handleGetData, getData, isPagination, isSearch, user } = props
  const rows = (data && data.content) || []
  const classes = useStyles()

  const [page, setPage] = React.useState(types.PAGINATION.PAGE)
  const [dense, setDense] = React.useState(true)
  const [rowsPerPage, setRowsPerPage] = React.useState(types.PAGINATION.SIZE)
  const [search, setSearch] = useState('')
  const history = useHistory()

  const tourOverview = useSelector(
    selectGuideListByKey(GUIDE_TYPE_ENUM.fanpage_manager)
  )
  const [tourConfig, setTourConfig] = useState(null)

  useEffect(() => {
    if (
      getIsGuideByKey(GUIDE_TYPE_ENUM.fanpage_manager) &&
      tourOverview?.length
    ) {
      setTourConfig(tourOverview)
      setIsGuideByKey(GUIDE_TYPE_ENUM.fanpage_manager)
    }
  }, [tourOverview])

  // useEffect(() => {
  //   const params = {
  //     page: 0,
  //     size: rowsPerPage,
  //     search: search ? search : undefined,
  //     sort: "desc",
  //   };
  //   getData(params);
  // }, []);

  useEffect(() => {
    handleSubmitSearch()
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    handleGetData({
      page: newPage,
      size: rowsPerPage,
      search: search ? search : undefined,
      sort: 'name,asc',
    })
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, types.PAGINATION.SIZE))
    setPage(types.PAGINATION.PAGE)
    handleGetData({
      page: types.PAGINATION.PAGE,
      size: parseInt(event.target.value, types.PAGINATION.SIZE),
      search: search ? search : undefined,
      sort: 'name,asc',
    })
  }

  const handleChangeDense = (event) => {
    setDense(event.target.checked)
  }

  const handleSearch = (key, value) => {
    switch (key) {
      case 'search':
        setSearch(value)
        break
      default:
        break
    }
  }

  const handleSubmitSearch = () => {
    const params = {
      page: types.PAGINATION.PAGE,
      size: types.PAGINATION.SIZE,
      sort: 'desc',
    }
    if (search) {
      params.search = `id|name==` + search
    }
    getData(params)
    setRowsPerPage(types.PAGINATION.SIZE)
    setPage(types.PAGINATION.PAGE)
  }

  const handleSubmitOpenExtension = () => {
    window.open(
      types.URL.FACEBOOK + `&token=${localStorage.getItem('token') || null}`,
      '_blank'
    )
  }
  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmitSearch()
    }
  }
  const handleChange = (event, value) => {
    setPage(value)
    handleGetData({
      page: value - 1,
      size: rowsPerPage,
      search: search ? search : undefined,
      sort: 'name,asc',
    })
  }
  const handleClickFanpage = (id) => {
    history.push({
      pathname: pathKeys.CUSTOMER,
      search: `?page=0&size=10&pageId=${id}&sort=createdAt%2Cdesc`,
    })
  }
  return (
    <>
      <Tour
        steps={tourConfig}
        isOpen={tourConfig != null}
        closeWithMask={false}
        lastStepNextButton={
          <Button size="small" className="btn--rounder" variant="contained">
            {t('common.btn.close')}
          </Button>
        }
        accentColor="#05ae34"
        onRequestClose={() => setTourConfig(null)}
      />
      <div className={classes.wrapperSearch}>
        <Flex align="center" justify="space-between" className="mt-3 w-100">
          <Flex align="center" auto>
            <TextField
              variant="outlined"
              size="small"
              className="bg-white"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              placeholder={t('fanpage_management.search')}
              style={{ width: 500 }}
              onKeyDown={onKeyDown}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => handleSubmitSearch()}
              style={{
                backgroundColor: '#00AB55',
                color: 'white',
                textTransform: 'none',
                minWidth: '100px',
                height: '40px',
                marginLeft: '12px',
              }}
            >
              Tìm kiếm
            </Button>
          </Flex>
          {/* <TextField
              variant="outlined"
              size="small"
              color="secondary"
              className="my-2"
              style={{ width: 400 }}
              value={search}
              onChange={(event) => handleSearch("search", event.target.value)}
              startAdornment={
                <InputAdornment position="start" label="Standard">
                  <SearchIcon />
                </InputAdornment>
              }
            /> */}
          <div>
            <ButtonComponent
              id="scan_customer"
              className={cn(
                classes.buttonFilter,
                'btn--rounder',
                'btn--primary--filled',
                'mx-3'
              )}
              title={
                <p>
                  <SearchIcon />
                  <span>{t('common.btn.scan_customer')}</span>
                </p>
              }
              backgroundColor="#05AE34"
              color="white"
              handleClickButton={() => handleSubmitOpenExtension()}
            />
          </div>
        </Flex>
      </div>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead classes={classes} />
              <TableBody>
                {(rows || []).map((row, index) => {
                  return (
                    <TableRow
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      className="row__custom"
                      onClick={() => handleClickFanpage(row.id)}
                    >
                      <TableCell align="right">
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell align="right">{row.name}</TableCell>
                      <TableCell align="right">{row.id}</TableCell>
                      <TableCell align="right">
                        {moment(row.updatedAt).format('DD/MM/YYYY HH:mm')}
                      </TableCell>
                      <TableCell align="right">{row.totalCus}</TableCell>
                      {/* <TableCell align="right">
                        <ButtonComponent
                          // className={classes.buttonSearch}
                          title={"Quét khách hàng"}
                          backgroundColor="#05AE34"
                          color="white"
                          handleClickButton={() => handleSubmitOpenExtension()}
                        />
                      </TableCell> */}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length == 0 && (
            <p className="py-4 text-muted text-center">{t('update.no_data')}</p>
          )}
          {isPagination && rows.length > 0 && (
            <>
              <TablePagination
                rowsPerPageOptions={[types.PAGINATION.SIZE, 20, 30, 50]}
                component="div"
                count={parseInt(data && data.totalElements)}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={t('update.display')}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                className={'pagination'}
              />
              <Pagination
                count={Math.ceil((data && data.totalElements) / rowsPerPage)}
                page={page == 0 ? page + 1 : page}
                onChange={handleChange}
              />
            </>
          )}
        </Paper>
        {/* {rows.length > 0 && (
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Thay đổi khoảng cách hiển thị gần"
          />
        )} */}
      </div>
    </>
  )
}

EnhancedTable.propTypes = {
  data: PropTypes.array,
  search: PropTypes.string,
  isSearch: PropTypes.bool,
  isPagination: PropTypes.bool,
  getData: PropTypes.func,
  handleGetData: PropTypes.func,
}

EnhancedTable.defaultProps = {
  isPagination: true,
  isSearch: true,
  handleGetData: (f) => f,
  getData: (f) => f,
  data: [],
  search: null,
}
