import { showError } from 'stores/alert/slice.alert'
import queryString from 'query-string'
import moment from 'moment'
import _ from 'lodash'

export const GUIDE_KEY = 'guide'
export const GUIDE_TYPE_ENUM = {
  account_manager: 'account_manager',
  customer_manager: 'customer_manager',
  fanpage_manager: 'fanpage_manager',
  overview: 'overview',
  video_introduce: 'video_introduce',
}
const GUIDE_DEFAULT = {
  account_manager: true,
  customer_manager: true,
  fanpage_manager: true,
  overview: true,
  video_introduce: true,
}

export const initGuide = () => {
  let data = localStorage.getItem(GUIDE_KEY)
  if (!data) {
    localStorage.setItem(GUIDE_KEY, JSON.stringify(GUIDE_DEFAULT))
  }
}

export const isGetGuideData = () => {
  let data = localStorage.getItem(GUIDE_KEY)
  if (!data) {
    initGuide()
    return true
  }
  data = JSON.parse(data)
  return Object.keys(data).some((key) => data[key])
}

export const getIsGuideByKey = (key) => {
  let data = localStorage.getItem(GUIDE_KEY)
  if (!data) return false
  data = JSON.parse(data)
  return data[key]
}

export const setIsGuideByKey = (key) => {
  let data = localStorage.getItem(GUIDE_KEY)
  if (!data) return false
  data = JSON.parse(data)
  data = {
    ...data,
    [key]: false,
  }
  localStorage.setItem(GUIDE_KEY, JSON.stringify(data))
}

export const removeVNLang = (str) => {
  if (str) {
    str = str.toLowerCase()
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
    str = str.replace(/đ/g, 'd')
    // str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
    str = str.replace(/ + /g, ' ')
    str = str.trim()
  }
  return str
}

export const openNewTab = (url) => {
  var link = document.createElement('a')
  link.target = '_blank'
  link.href = url
  link.click()
}

export const handleUpgrade = ({ i18n, user, dispatch }) => {
  if (!user?.id) {
    dispatch(
      showError(
        'Có lỗi xảy ra, vui lòng liên hệ với BOMBOT!. (An error has occurred, please contact BOMBOT!).'
      )
    )
    return
  }
  if (i18n.language) {
    let url
    const obj = {
      clear_cart: true || '',
      full_name: user.name || '',
      ref: user.id || '',
      province_id: 50,
      company: user.company || '',
      phone: user.phone || '',
      email: user.email || '',
    }
    const params = queryString.stringify(obj)

    switch (i18n.language?.substr(0, 2)) {
      case 'vi': {
        url = `https://bombot.vn?${params}#bang-gia`
        break
      }
      case 'kh': {
        url = `https://bombot.vn/km/home?${params}#kh-pricing`
        break
      }
      default: {
        // default en
        url = `https://bombot.vn/en/home?${params}#pricing`
        break
      }
    }

    openNewTab(url)
  }
}

export const createSearchQuery = (values) => {
  if (_.isEmpty(values)) return ''

  const filtered = values.filter(
    (e) => e.value && !(Array.isArray(e.value) && e.value.length === 0)
  )
  let payload = ''

  filtered.forEach((condition) => {
    if (condition.operation === '==' || condition.operation === '!=') {
      payload +=
        condition.field +
        condition.operation +
        encodeURIComponent(condition.value) +
        ';'
    } else {
      switch (condition.field) {
        case 'bombotTags':
        case 'fbTags':
          payload +=
            condition.field +
            '=' +
            condition.operation +
            '=' +
            condition.value?.map((v) => v.value).toString() +
            ';'
          break
        case 'phone':
        case 'gender':
          payload +=
            condition.field +
            '=' +
            condition.operation +
            '=' +
            encodeURIComponent(removeVNLang(condition.value)) +
            ';'
          break
        case 'uid':
        case 'name':
          payload +=
            condition.field +
            '=' +
            condition.operation +
            '=' +
            removeVNLang(condition.value) +
            ';'
          break
        case 'lastInboxTime':
          payload +=
            condition.field +
            '=' +
            condition.operation +
            '=' +
            condition.value
              .map((v) => moment(v).format('YYYY-MM-DD'))
              .toString() +
            ';'
          break
        case 'lastCustomerInboxTime':
          payload +=
            condition.field +
            '=' +
            condition.operation +
            '=' +
            condition.value
              .map((v) => moment(v).format('YYYY-MM-DD'))
              .toString() +
            ';'
          break
        default:
          break
      }
    }
  })

  return payload
}
