import React, { useRef, useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { pathKeys } from '../../constants'
import FormDialog from '../../components/FormDialog'
import ButtonComponent from '../../components/Button'
import types from '../../constants/types'
import IMAGES from '../../images'
import Loading from '../../components/Loading'
import { useTranslation } from 'react-i18next'
import i18n from 'utils/i18n'
const useStyles = makeStyles((theme) => ({
  root: {
    background: 'no-repeat top',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundSize: 'cover',
  },
  wrapperLogin: {
    width: 445,
    minHeight: 500,
    border: '1px solid #f0f0f0',
    background: '#F7FAFC',
    borderRadius: '.375rem',
  },
  wrapBox: {
    textAlign: 'center',
    paddingBottom: 30,
  },
  error: {
    color: 'red',
    textAlign: 'center',
  },
}))
const InvitePageView = (props) => {
  const {
    confirmInviteCode,
    user,
    onGetUser,
    login,
    statusConfirmInviteCode,
    loading,
  } = props
  const classes = useStyles()
  const history = useHistory()
  const [inviteCodeData, setInviteCodeData] = useState('')
  const [isInviteCodePopup, setIsInviteCodePopup] = useState(false)
  const { t } = useTranslation()
  useEffect(() => {
    if (statusConfirmInviteCode) {
      history.push(pathKeys.HOME)
    }
  }, [statusConfirmInviteCode])

  useEffect(() => {
    const uid = localStorage.getItem('id')
    if (uid) {
      onGetUser(uid)
    }
  }, [onGetUser])

  useEffect(() => {
    const url_string = window.location.href //window.location.href
    const url = new URL(url_string)
    const ref = url.searchParams.get('ref')
    const code = url.searchParams.get('code')
    const pathname = url.pathname
    console.log('code', code)
    setInviteCodeData(code)
  }, [])

  const handleCloseInviteCodePopup = () => {
    // setIsInviteCodePopup(false);
  }
  const handleSubmitInviteCode = () => {
    // onGetInviteCode();
    // setIsInviteCodePopup(true);
  }

  const handleSubmitButton = async () => {
    if (_.isEmpty(user)) {
      login()
    } else {
      await confirmInviteCode(inviteCodeData)
      const uid = localStorage.getItem('id')
      if (uid) {
        onGetUser(uid)
      }
    }
    // onGetInviteCode();
    // setIsInviteCodePopup(true);
  }

  const background = useMemo(() => {
    return i18n.language?.substr(0, 2) === 'vi'
      ? IMAGES.BACKGROUND
      : IMAGES.BACKGROUND_INTE
  }, [i18n.language])

  return (
    <div
      className={classes.root}
      style={{ backgroundImage: `url(${background})` }}
    >
      {loading && <Loading />}
      <div className={classes.wrapperLogin}>
        <FormDialog
          isOpen={true}
          // handleClose={() => handleCloseInviteCodePopup()}
        >
          <div className={classes.wrapBox}>
            <h4 className={classes.title}>{t('update.invite')}</h4>
            {!statusConfirmInviteCode && statusConfirmInviteCode != null && (
              <p className={classes.error}>{t('update.error_invite')}</p>
            )}
            <ButtonComponent
              className={classes.buttonChange}
              title={
                _.isEmpty(user) ? t('common.btn.login') : t('update.accept')
              }
              backgroundColor={_.isEmpty(user) ? '#01a350' : '#FF8000'}
              color="white"
              handleClickButton={() => handleSubmitButton()}
            />
          </div>
        </FormDialog>
      </div>
    </div>
  )
}

InvitePageView.propTypes = {}

InvitePageView.defaultProps = {}

export default InvitePageView
