import { createSlice } from '@reduxjs/toolkit'
import initialState from '../../configs/redux/initialState'
import _ from 'lodash'
const customerReducer = createSlice({
  name: "customer",
  initialState: {
    ...initialState.customerReducer,
    filter: {
      search_obj: {},
      search_string: "",
      search_arr: []
    },
    loading_add_tag: false,
    is_show_modal_add: false,
    tagsSelected: [],
    importResult: null,

    searchUidName: ""
  },
  reducers: {
    setCustomer: (state, action) => {
      state.customer = action.payload;
    },
    // setEdit: (state, action) => {
    //   state.edit = action.payload;
    // },
    setDetail: (state, action) => {
      state.detail = action.payload;
    },
    setFanpageSelected: (state, action) => {
      state.fanpageSelected = action.payload;
    },
    setTagFacebookSelectedAll: (state, action) => {
      state.tagFacebookSelectedAll = action.payload;
    },
    setSelectTagFacebook: (state, action) => {
      state.selectTagFacebook = action.payload;
    },
    setLoadingImport: (state, action) => {
      state.loadingImport = action.payload;
    },
    setImportSuccess: (state, action) => {
      state.importSuccess = action.payload;
    },
    setFanPageDetail: (state, action) => {
      state.fanPageDetail = action.payload;
    },
    setCustomerAll: (state, action) => {
      state.customerAll = action.payload;
    },
    setCustomerCheck: (state, action) => {
      if (!action.payload.checked) {
        _.remove(state.customerCheck, function (n) {
          return n.id == action.payload.id;
        });
        state.customerCheck = _.concat(...state.customerCheck);
      } else {
        state.customerCheck = _.concat(...state.customerCheck, action.payload);
      }
    },
    setRestCustomerCheck: (state, action) => {
      state.customerCheck = action.payload;
    },
    setCreateCustomerSucss: (state, action) => {
      state.createCustomerSucss = action.payload;
    },
    setMessageImport: (state, action) => {
      state.messageImport = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        search_arr: JSON.parse(JSON.stringify(action.payload.search_arr)), // Deep copy
        search_string: action.payload.search_string
      };
    },
    setSearchUidName: (state, action) => {
      state.searchUidName = action.payload;
    },
    setSearchTagName: (state, action) => {
      state.searchTagName = action.payload;
    },
    startAddTags: (state, action) => {
      state.error = null;
      state.loading_add_tag = true;
    },
    addTagsSuccess: (state, action) => {
      state.loading_add_tag = false;
      state.fanpageSelected = {
        ...state.fanpageSelected,
        ...action.payload
      };
      state.is_show_modal_add = false;
    },
    addTagsFailed: (state, action) => {
      state.loading_add_tag = false;
      state.error = action.payload;
    },
    toggleModalAdd: (state) => {
      state.is_show_modal_add = !state.is_show_modal_add;
    },
    importResult: (state, action) => {
      state.importResult = action.payload;
    },
    clearImportResult: (state) => {
      state.importResult = null;
    },
    setTagSelected: (state, action) => {
      state.tagsSelected = action.payload;
    }
  }
});

export const {
  setCustomer,
  setDetail,
  setEdit,
  setFanpageSelected,
  setTagFacebookSelectedAll,
  setSelectTagFacebook,
  setLoadingImport,
  setImportSuccess,
  setFanPageDetail,
  setCustomerAll,
  setCustomerCheck,
  setRestCustomerCheck,
  setCreateCustomerSucss,
  setMessageImport,
  setFilter,
  setSearchUidName,
  startAddTags,
  addTagsSuccess,
  addTagsFailed,
  toggleModalAdd,
  setTagSelected,
  importResult,
  clearImportResult,
} = customerReducer.actions

export default customerReducer.reducer
