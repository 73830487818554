export const FirebaseConfig = {
  apiKey: 'AIzaSyAXT7RncitAtPwbTck9UBYtYyY_vxYSS94',
  authDomain: 'bombot-13368.firebaseapp.com',
  projectId: 'bombot-13368',
  storageBucket: 'bombot-13368.appspot.com',
  messagingSenderId: '566498998743',
  appId: '1:566498998743:web:adae2e0623bc157fde3392',
  measurementId: 'G-7227W64HF5',
}

export const vapidKey =
  'BEdVrkl_sB-fT48WQPCNtmAhjrnkjRLOR6jhlcYxcXcEitpRVEQIX2C6rXjAV7xgNzwDsotaW6XZ5mJABCQrNG0'
