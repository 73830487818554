import React from 'react'
import ButtonComponent from '../Button'
import { useTranslation } from 'react-i18next'
import FormDialog from '../FormDialog'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '15px 15px 15px 0px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  noData: {
    color: 'rgba(0,0,0,.5)',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingBottom: 15,
  },
  buttonIEA: {
    width: '100%',
    textAlign: 'right',
    marginRight: '15px',
    paddingRight: '15px',
    '& button': {
      marginLeft: 15,
    },
  },
  avatar: {
    margin: '0px auto',
  },
  titlePopup: {
    textAlign: 'center',
    color: '#05AE34',
  },
  wrapButtonPopup: {
    textAlign: 'center',
  },
}))

const ConfirmModal = ({ open, handleSubmit, handleCancel, title }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <FormDialog isOpen={open}>
      <div className={cn(classes.wrapChangePassword, 'p-4')}>
        <p style={{ textAlign: 'center' }} className="mb-2 fz-15">
          {title}
        </p>
        <div className="text-center mt-4">
          <ButtonComponent
            className="mr-2"
            title={t('common.btn.cancel')}
            backgroundColor="#BDBDBD"
            color="white"
            handleClickButton={handleCancel}
          />
          <ButtonComponent
            title={t('common.btn.confirm')}
            backgroundColor="#05AE34"
            color="white"
            handleClickButton={handleSubmit}
          />
        </div>
      </div>
    </FormDialog>
  )
}

export default ConfirmModal
