import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import cn from 'classnames'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import SettingsIcon from '@material-ui/icons/Settings'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import EditIcon from '@material-ui/icons/Edit'
import moment from 'moment'
import types from '../constants/types'
import ButtonComponent from './Button'
import FilterListIcon from '@material-ui/icons/FilterList'
import Pagination from '@material-ui/lab/Pagination'
import FormDialog from './FormDialog'
import TextField from '@material-ui/core/TextField'
import Flex from './Flex'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ModalTagsBombot from '../components/Modals/ModalTagsBombot'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import GetAppIcon from '@material-ui/icons/GetApp'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import MuiAlert from '@material-ui/lab/Alert'
import {
  Button,
  FormControlLabel,
  Snackbar,
  TablePagination,
} from '@material-ui/core'
import FilterCustomerModal from './FilterCustomer'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectFilterCus,
  selectTagsPageSelected,
} from 'views/Customer/CustomerSelectors'
import useEffectIgnoreFirst from 'hook/useEffectIgnoreFirst'
import { useTranslation } from 'react-i18next'
import { createSearchQuery } from '../utils/helper'
import { setSearchUidName } from '../views/Customer/CustomerReducer'
import TagsModal, { colorDir1, getRandomTag } from './TagPreviewModal'
import TagPreviewItem from './TagPreviewItem'
import { Tag } from 'react-feather'
import ManageTagModal from './ManageTag'
import customerApi from '../api/customerApi'
import { getCustomer } from '../views/Customer/CustomerActions'
import TagsManagementModal from './TagsManagementModal/TagsManagementModal'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const headCells = [
  // {
  //   id: "actions",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Thao tác",
  // },
  {
    id: 'ord_num',
    numeric: false,
    disablePadding: false,
    label: 'customer_management.list.ord_number',
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'customer_management.list.uid',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'customer_management.list.facebook_name',
    width: 100,
  },
  {
    id: 'gt',
    numeric: false,
    disablePadding: false,
    label: 'customer_management.list.gender',
  },
  {
    id: 'timeInbox',
    numeric: false,
    disablePadding: false,
    label: 'customer_management.list.last_inbox_time',
  },
  {
    id: 'timeCustomerInbox',
    numeric: false,
    disablePadding: false,
    label: 'customer_management.list.last_customer_inbox_time',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'customer_management.list.phone',
  },
  {
    id: 'tagF',
    numeric: false,
    disablePadding: false,
    label: 'customer_management.list.facebook_tag',
  },
  {
    id: 'tagB',
    numeric: false,
    disablePadding: false,
    label: 'customer_management.list.bombot_tag',
  },
  {
    id: 'TimeLast',
    numeric: false,
    disablePadding: false,
    label: 'customer_management.list.last_update_time',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'customer_management.list.action',
  },
]

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    numSelected,
    rowCount,
    checkBoxSelectAll,
    isEmptyCheckItem,
  } = props
  const { t } = useTranslation()
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={isEmptyCheckItem.length > 0 ? false : checkBoxSelectAll}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            style={{ width: '12.5%' }}
          >
            <TableSortLabel hideSortIcon={true}>
              {t(headCell.label)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '15px 15px 60px 0px',
    '& .MuiTableCell-paddingCheckbox': {
      width: 130,
    },
  },
  paper: {
    width: '100%',
    marginBottom: '80px',
  },
  table: {
    minWidth: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  '.pagination': {
    '& .MuiTablePagination-input': {
      width: 70,
    },
  },

  wrapperSearch: {
    position: 'relative',
  },

  buttonIEA: {
    width: '100%',
    // marginTop: 10,
    textAlign: 'left',
    marginRight: '15px',
    padding: '20px 15px 15px 0px',
    position: 'fixed',
    bottom: 60,
    background: '#F6F9FC',
    '& button': {
      marginRight: 15,
    },
  },
  noData: {
    color: 'rgba(0,0,0,.5)',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingBottom: 15,
  },
  iconCheck: {
    '& .MuiSvgIcon-root': {
      width: '40',
      height: '40',
    },
  },
  icon: {
    marginLeft: 5,
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      width: '40',
      height: '40',
    },
  },
  classTags: {
    '&:last-child': {
      '& span': {
        display: 'none',
      },
    },
  },
  wrapBox: {
    padding: '15px',
    marginBottom: 30,
  },
  inputAdd: {
    width: '100%',
    marginBottom: 15,
  },
  wrapButton: {
    textAlign: 'center',
  },
  buttonLoadMore: {
    border: '0px',
    color: 'var(--primary-color) !important',
    backgroundColor: 'unset',
  },

  witdhSeq: {
    minWidth: '120px',
    width: '4%',
  },

  witdhCheckBox: {
    minWidth: '60px',
    width: '4%',
  },

  witdhUid: {
    minWidth: '60px',
    width: '15%',
    padding: '0 8px !important',
  },

  widthName: {
    minWidth: '160px',
    width: '15%',
    padding: '0 8px !important',
  },

  widthGender: {
    minWidth: '120px',
    width: '6%',
    padding: '0 8px !important',
  },

  widthPhone: {
    minWidth: '120px',
    width: '15%',
    padding: '0 8px !important',
  },

  widthDate: {
    minWidth: '180px',
    width: '15%',
    padding: '0 8px !important',
  },

  widthLinkFB: {
    minWidth: '120px',
    width: '15%',
    padding: '0 8px !important',
  },

  witdhAction: {
    minWidth: '120px',
    width: '4%',
  },

  widthTag: {
    minWidth: '140px',
    width: '15%',
    padding: '12px 8px !important',
  },

  witdhUidErr: {
    minWidth: '140px',
    width: '20%',
    padding: '0 8px !important',
  },

  p0: {
    padding: '0',
    margin: '0',
  },

  heightHeader: {
    height: '52px',
  },

  f600: {
    fontWeight: 600,
  },

  checkBoxPadding: {
    padding: '10px 20px',
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '10px',
  },

  closeBtn: {
    color: '#ffffff', // Màu chữ trắng
    backgroundColor: '#dc3545', // Màu nền đỏ để nổi bật
    border: '2px solid #dc3545', // Viền có màu giống nền
    padding: '6px 20px', // Padding cho nút
    borderRadius: '4px', // Đường viền tròn cho nút
    cursor: 'pointer', // Con trỏ chuột khi hover
    margin: '0 36px !important',
    '&:hover': {
      backgroundColor: '#c82333', // Màu nền khi hover (đỏ đậm hơn)
      borderColor: '#bd2130', // Màu viền khi hover
    },
  },

  submitBtn: {
    color: 'white', // Màu chữ
    backgroundColor: '#28a745', // Màu nền
    border: '2px solid #28a745', // Viền
    padding: '6px 26px', // Padding cho nút
    borderRadius: '4px', // Đường viền tròn cho nút
    cursor: 'pointer', // Con trỏ chuột khi hover.
    margin: '0 36px !important',
    '&:hover': {
      backgroundColor: '#218838', // Màu nền khi hover
      borderColor: '#1e7e34', // Màu viền khi hover
    },
  },

  numCusInp: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },

  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    margin: '12px 0',
    minHeight: '48px',
  },

  container: {
    flex: 1,
    width: '100%',
  },

  paginationBottom: {
    display: 'flex !important',
    justifyContent: 'end !important',
    alignItems: 'center !important',
  },

  buttonLoadMore: {
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: '#fff',
    border: '1px solid #dbdbdb',
    borderRadius: '.375em',
    boxShadow: 'none',
    boxSizing: 'border-box',
    color: '#363636',
    cursor: 'pointer',
    display: 'inline-flex',
    fontFamily: `BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, Arial, sans-serif`,
    fontSize: '0.8rem',
    height: '1.8em',
    justifyContent: 'center',
    lineHeight: '1.5',
    padding: 'calc(.5em - 1px) 1em',
    position: 'relative',
    textAlign: 'center',
    userSelect: 'none',
    WebkitUserSelect: 'none',
    touchAction: 'manipulation',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    width: '100%',

    '&:hover': {
      borderColor: '#b5b5b5',
    },
    '&:active': {
      borderColor: '#4a4a4a',
      outline: '0',
    },
    '&:focus': {
      borderColor: '#485fc7',
      outline: '0',
    },
    '&:focus:not(:active)': {
      boxShadow: 'rgba(72, 95, 199, 0.25) 0 0 0 0.125em',
    },
  },

  rowItem: {
    cursor: 'pointer',
  },

  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
    padding: '4px 0 !important',
  },
}))
let timeout = 0
export default function EnhancedTable(props) {
  const {
    data,
    handleGetData,
    getData,
    isPagination,
    isSearch,
    fanpageSelected,
    detail,
    edit,
    handleExport,
    handleImport,
    handleFilter,
    onCreate,
    customerAll,
    onCustomerCheck,
    onCustomerCheckReset,
    customerCheck,
    createCustomerSucss,
    paramsFilter,
    paramsPagination,
    getFanpage,
  } = props
  // const rows = (data && data.content) || [];
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(types.PAGINATION.PAGE)
  const [dense, setDense] = useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(types.PAGINATION.SIZE)
  const [search, setSearch] = useState('')
  const [checkBoxSelectAll, setCheckBoxSelectAll] = useState(true)
  const [rows, setRows] = useState([])
  const [isCreateCampaign, setIsCreateCampaign] = useState(false)
  const [titleCampaign, setTitleCampaign] = useState('')
  const [isCheckedAllPage, setIsCheckedAllPage] = useState(true)
  const [selectedTags, setSeletedTags] = useState(null)

  const [isUseCheckedAllPage, setIsUseCheckedAllPage] = useState(true)

  const [customerAllState, setCustomerAllState] = useState([])
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [typeModalTag, setTypeModalTag] = useState(null)
  const [error, setError] = useState('')
  const [typeMessage, setTypeMessage] = useState(null)
  const [customerAfterFilter, setcustomerAfterFilter] = useState([])
  const [showModalAllTags, setshowModalAllTags] = useState(false)
  const [selectedModalTags, setSelectedModalTags] = useState([])
  const [isOpenModalManageTag, setIsOpenModalManageTag] = useState(false)
  const [isOpenModalFilter, setIsOpenModalFilter] = useState(false)

  const tagsObj = useSelector(selectTagsPageSelected)

  const dispatch = useDispatch()

  const history = useHistory()

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search)
    const page = searchParam.get('page')
    if (page) {
      setPage(_.toNumber(page) + 1)
    }
  }, [paramsFilter])
  useEffect(() => {
    if (createCustomerSucss) {
      setIsCreateCampaign(false)
    }
  }, [createCustomerSucss])
  useEffect(() => {
    setCustomerAllState(customerAll)
  }, [customerAll])

  let totalNumber = data && data.totalElements - customerCheck.length

  useEffect(() => {
    setRows((data && data.content) || [])
  }, [data])

  // useEffect(() => {
  //   if (timeout) clearTimeout(timeout)
  //   timeout = setTimeout(() => {
  //     dispatch(setSearchUidName(search))
  //     handleSubmitSearch()
  //   }, 300)
  // }, [search, fanpageSelected])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    getData({
      page: newPage,
      size: rowsPerPage,
      search: `pageId==${fanpageSelected.id};${
        search && `uid|name==` + search
      }`,
      sort: 'lastInboxTime,desc',
    })
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, types.PAGINATION.SIZE))
    setPage(types.PAGINATION.PAGE)
    getData({
      page: types.PAGINATION.PAGE,
      size: parseInt(event.target.value, types.PAGINATION.SIZE),
      search: `pageId==${fanpageSelected.id};${
        search && `uid|name==` + search
      }`,
      sort: 'lastInboxTime,desc',
    })
    const searchParam = new URLSearchParams(window.location.search)
    searchParam.set('size', parseInt(event.target.value, types.PAGINATION.SIZE))
    const newSearchParam = searchParam.toString()
    history.push({
      pathname: window.location.pathname,
      search: newSearchParam,
    })
  }

  const filterCus = useSelector(selectFilterCus)
  const handleChange = (event, value) => {
    const searchParam = new URLSearchParams(window.location.search)
    searchParam.set('page', value - 1)
    const search = searchParam.get('search')
    const newSearchParam = searchParam.toString()
    history.push({
      pathname: window.location.pathname,
      search: newSearchParam,
    })
    paramsPagination({
      page: value,
      size: rowsPerPage,
      pageId: fanpageSelected.id,
    })
    //setCheckBoxSelectAll(true);
    setPage(value)
    if (filterCus?.search_string) {
      getData({
        page: value - 1,
        size: 10,
        search: filterCus?.search_string,
        sort: 'lastInboxTime,desc',
      })
    } else {
      getData({
        page: value - 1,
        size: rowsPerPage,
        search: `${
          search
            ? search
            : `pageId==${fanpageSelected.id};${search && `uid|name==` + search}`
        }`,
        sort: 'lastInboxTime,desc',
      })
    }
  }
  const handleChangeDense = (event) => {
    setDense(event.target.checked)
  }

  const handleSearch = (key, value) => {
    switch (key) {
      case 'search':
        // setSearch(value)
        // if (value) {
        const customerFilter = customerAll.filter(
          (cus) =>
            (cus.uid && cus.uid.includes(value)) ||
            (cus.nameText && cus.nameText.includes(value))
        )
        setcustomerAfterFilter(customerFilter)
        dispatch(setSearchUidName(search))
        handleSubmitSearch()
        // }
        break
      default:
        break
    }
    // setTypeMessage("error");
  }

  const handleSubmitSearch = () => {
    if (fanpageSelected?.id) {
      const searchParam = new URLSearchParams(window.location.search)
      searchParam.set('page', types.PAGINATION.PAGE)
      searchParam.set('size', types.PAGINATION.SIZE)
      searchParam.set('pageId', `${fanpageSelected.id}`)
      searchParam.set('sort', 'lastInboxTime,desc')
      const getSearch = searchParam.get('search')
      const newSearchParam = searchParam.toString()
      history.push({
        pathname: window.location.pathname,
        search: newSearchParam,
      })
      paramsPagination({
        page: types.PAGINATION.PAGE,
        size: types.PAGINATION.SIZE,
        pageId: fanpageSelected.id,
      })
      if (!_.isEmpty(fanpageSelected)) {
        let searchQuery = createSearchQuery(filterCus?.search_arr || null)

        searchQuery += `${
          getSearch ? getSearch : `pageId==${fanpageSelected.id}`
        };${search && `uid|name==` + search}`

        const params = {
          page: types.PAGINATION.PAGE,
          size: types.PAGINATION.SIZE,
          search: searchQuery,
          sort: 'lastInboxTime,desc',
        }
        getData(params)
        setRowsPerPage(types.PAGINATION.SIZE)
        setPage(types.PAGINATION.PAGE)
      }
    }
  }

  const handleSubmitFilter = () => {
    setIsOpenModalFilter(true)
  }

  const toggleModalFilter = () => {
    setIsOpenModalFilter(!isOpenModalFilter)
  }

  const onSubmitFilter = (data) => {
    setcustomerAfterFilter(data)
  }
  const handleDetail = (item) => {
    detail(item)
  }

  const handleEdit = (item) => {
    const searchParam = new URLSearchParams(window.location.search)
    const getSearch = searchParam.get('search')
    const params = {
      page: types.PAGINATION.PAGE,
      size: types.PAGINATION.SIZE,
      search: `${getSearch ? getSearch : `pageId==${fanpageSelected.id}`};${
        search && `uid|name==` + search
      }`,
      sort: 'lastInboxTime,desc',
    }
    edit(item, params)
  }
  const handleSubmitExport = () => {
    handleExport()
  }
  const handleSubmitImport = () => {
    handleImport()
  }
  const handleOpenExtention = () => {
    window.open(
      types.URL.FACEBOOK + `&token=${localStorage.getItem('token') || null}`,
      '_blank'
    )
  }
  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch('search', search)
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1

  const handleSelectAllClick = () => {
    let items = []
    const checkArr = data && data.content.find((item) => item.checked == false)
    if (checkBoxSelectAll && _.isEmpty(checkArr)) {
      ;(rows || []).map((item) => {
        onCustomerCheck({ ...item, checked: true })
        return items.push({
          ...item,
          checked: true,
        })
      })
      setCheckBoxSelectAll(false)
    } else {
      ;(rows || []).map((item) => {
        onCustomerCheck({ ...item, checked: false })
        return items.push({
          ...item,
          checked: false,
        })
      })
      setCheckBoxSelectAll(true)
    }
    setRows(items)
  }

  const handleSelectCheckBox = (value) => {
    let items = []
    ;(rows || []).map((item) => {
      if (item.id == value.id && value.checked == true) {
        onCustomerCheck(item)
        return items.push({
          ...item,
          checked: false,
        })
      } else if (item.id == value.id && value.checked == false) {
        onCustomerCheck(item)
        return items.push({
          ...item,
          checked: true,
        })
      } else {
        return items.push({
          ...item,
        })
      }
    })
    setRows(items)
  }
  const handleSubmitCreate = () => {
    setIsCreateCampaign(true)
  }
  const handleCloseCreatePopup = () => {
    setIsCreateCampaign(false)
    setError('')
    setTitleCampaign('')
  }

  const handleSubmitCreateCampaign = () => {
    const params = {
      name: titleCampaign,
      pageId: fanpageSelected.id,
    }
    if (titleCampaign) {
      onCreate(paramsFilter, customerCheck, params, data)
      setError('')
      setTitleCampaign('')
      setIsCreateCampaign(false)
    } else {
      setError(t('update.error_msg_name_camp'))
    }
  }

  const handleChangeCampaign = (key, value) => {
    switch (key) {
      case 'titleCampaign':
        setTitleCampaign(value)
        break
      default:
        break
    }
  }
  const handleCheckedAllPage = () => {
    setIsUseCheckedAllPage(true)
    let items = []
    let customerAll = []
    if (isCheckedAllPage && customerCheck.length == 0) {
      ;((data && data.content) || []).map((item) => {
        return items.push({
          ...item,
          checked: false,
        })
      })
      customerAllState.map((item) => {
        return customerAll.push({
          ...item,
          checked: false,
        })
      })
      onCustomerCheckReset(customerAllState)
      setIsCheckedAllPage(false)
      setCheckBoxSelectAll(false)
    } else {
      ;((data && data.content) || []).map((item) => {
        return items.push({
          ...item,
          checked: true,
        })
      })
      customerAllState.map((item) => {
        return customerAll.push({
          ...item,
          checked: true,
        })
      })
      onCustomerCheckReset([])
      setIsCheckedAllPage(true)
      setCheckBoxSelectAll(true)
    }
    setCustomerAllState(customerAllState)

    setRows(items)
  }
  let isEmptyCheckItem = []
  rows.map((item) => {
    if (!item.checked) {
      isEmptyCheckItem.push(item)
    }
  })

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const openModalTag = (type) => {
    setTypeModalTag(type)
    handleCloseMenu()
  }

  const onCloseModal = () => {
    setTypeModalTag(null)
  }

  const handleCloseShowModalAllTagsPopup = (tags) => {
    setshowModalAllTags(false)
    setSelectedModalTags([])
  }

  const { t } = useTranslation()

  const renderTagsCell = (tagList, type, row) => {
    const fanpageTags =
      type === 'bombotTag' ? tagsObj?.bombotTags : tagsObj?.fbTags

    const filterTags = tagList.filter((tag) => fanpageTags[tag])

    return (
      <>
        {filterTags?.length ? (
          <div
            className={[
              classes.tagsContainer,
              classes.widthTag,
              classes.p0,
            ].join(' ')}
          >
            {filterTags.map((tag, index) => {
              const colorCode = getRandomTag(colorDir1)

              return (
                fanpageTags[tag]?.name &&
                parseInt(index) < 3 && (
                  <TagPreviewItem
                    styleText={{
                      maxWidth: '140px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    key={index}
                    background={
                      fanpageTags[tag]?.backgroundColor ||
                      `#${fanpageTags[tag]?.color}`
                    }
                    color={fanpageTags[tag]?.textColor || '#FFF'}
                    name={fanpageTags[tag]?.name}
                    size="small"
                  />
                )
              )
            })}
          </div>
        ) : (
          <p
            style={{
              fontStyle: 'italic',
              fontSize: '12px',
            }}
          >
            {'_'}
          </p>
        )}
        {(filterTags || []).length > 3 && (
          <button
            onClick={(e) => {
              e.stopPropagation()
              setSeletedTags({
                tagList: filterTags || null,
                customer: row,
                type: type,
              })
            }}
            className={classes.buttonLoadMore}
          >
            Xem tất cả ({(filterTags || []).length}) tags
          </button>
        )}
      </>
    )
  }

  const handleRemoveTags = async (customer, removingTags) => {
    const payload = {
      search: `pageId==${customer.pageId}`,
      exclude: [],
      bombotTags: [...removingTags],
      include: [customer.uid],
    }

    return customerApi.unAttachTags(payload).then((result) => {
      const searchParam = new URLSearchParams(window.location.search)
      const search = searchParam.get('search')

      paramsPagination({
        page: page,
        size: rowsPerPage,
        pageId: fanpageSelected.id,
      })
      //setCheckBoxSelectAll(true);
      if (filterCus?.search_string) {
        getData({
          page: page - 1,
          size: 10,
          search: filterCus?.search_string,
          sort: 'lastInboxTime,desc',
        })
      } else {
        getData({
          page: page - 1,
          size: rowsPerPage,
          search: `${
            search
              ? search
              : `pageId==${fanpageSelected.id};${
                  search && `uid|name==` + search
                }`
          }`,
          sort: 'lastInboxTime,desc',
        })
      }

      getFanpage()
    })
  }

  return (
    <>
      {typeMessage && (
        <Snackbar
          open={typeMessage != null}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}
          autoHideDuration={6000}
          // onClose={() => setTypeMessage(null)}
        >
          <Alert onClose={() => {}} severity={typeMessage}>
            {typeMessage === 'error'
              ? t('update.error_msg')
              : t('customer_management.import_customer.import_result.success')}
          </Alert>
        </Snackbar>
      )}
      <FormDialog
        isOpen={isCreateCampaign}
        handleClose={() => handleCloseCreatePopup()}
      >
        <div className="p-4">
          <h4 className={classes.title}>
            {t('customer_management.create_campaign.title')}
          </h4>
          <TextField
            error={false}
            label={t(
              'customer_management.create_campaign.create_campaign_name'
            )}
            variant="outlined"
            size="small"
            className={cn('w-100', 'my-4')}
            // defaultValue={addTag}
            onChange={(event) =>
              handleChangeCampaign('titleCampaign', event.target.value)
            }
          />
          <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
          <div className={classes.wrapButton}>
            <ButtonComponent
              className={cn(classes.buttonChange, 'btn--rounder')}
              title={t('common.btn.create')}
              backgroundColor="#05AE34"
              color="white"
              handleClickButton={() => handleSubmitCreateCampaign()}
            />
          </div>
        </div>
      </FormDialog>
      <FormDialog
        isOpen={showModalAllTags}
        handleClose={() => handleCloseShowModalAllTagsPopup()}
      >
        <div style={{ padding: '10px' }}>
          <h4 className={classes.title}>
            {t('customer_management.tags.list_title')}
          </h4>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {(selectedModalTags || []).map((item, idx) => {
              let obj = _.find(
                (fanpageSelected && fanpageSelected.bombotTags) || [],
                function (obj) {
                  return obj.id === item
                }
              )
              if (!_.isEmpty(obj)) {
                return (
                  <div key={idx} style={{ width: '50%', padding: '10px' }}>
                    <span
                      className={classes.classTags}
                      style={{
                        background: `#${obj.color}`,
                        color: obj.color === '000000' ? 'white' : '',
                        fontSize: '0.8125rem',
                        marginBottom: 3,
                        borderRadius: 16,
                        padding: '2px 5px',
                        display: 'block',
                        boxSizing: 'border-box',
                        width: '100%',
                      }}
                    >
                      {obj.name}
                    </span>
                  </div>
                )
              }
            })}
          </div>
        </div>
      </FormDialog>
      {isSearch && (
        <div className={classes.wrapperSearch}>
          <Flex align="center" justify="space-between" className="pr-4">
            <Flex align="center" auto>
              <TextField
                variant="outlined"
                size="small"
                onChange={(event) => setSearch(event.target.value)}
                placeholder={t('customer_management.search')}
                style={{ maxWidth: 500, width: '100%' }}
                id="input-with-icon-textfield"
                className="bg-white"
                onKeyDown={onKeyDown}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => handleSearch('search', search)}
                style={{
                  backgroundColor: '#00AB55',
                  color: 'white',
                  textTransform: 'none',
                  minWidth: '100px',
                  height: '40px',
                  marginLeft: '12px',
                }}
              >
                Tìm kiếm
              </Button>
            </Flex>
            <Flex align="center" className="ml-3" flexWrap="wrap">
              <ButtonComponent
                id="manage_tag"
                className="btn--rounder btn--primary mr-2"
                title={
                  <Flex align="center">
                    <Tag size={20} />
                    <p className="ml-2">{t('common.btn.manage_tag')}</p>
                  </Flex>
                }
                backgroundColor="#05AE34"
                color="white"
                disabled={_.isEmpty(fanpageSelected) ? true : false}
                handleClickButton={() => setIsOpenModalManageTag(true)}
              />
              <ButtonComponent
                id="filter_customer"
                className="btn--rounder btn--primary mr-2"
                title={
                  <Flex align="center">
                    <FilterListIcon />
                    <p className="ml-2">{t('common.btn.filter_customer')}</p>
                  </Flex>
                }
                backgroundColor="#05AE34"
                color="white"
                disabled={_.isEmpty(fanpageSelected) ? true : false}
                handleClickButton={() => handleSubmitFilter()}
              />
              <ButtonComponent
                className="btn--rounder btn--primary"
                title={
                  <Flex align="center">
                    <SettingsIcon />
                    <p className="ml-2">{t('common.btn.update_info')}</p>
                  </Flex>
                }
                backgroundColor="#05AE34"
                color="white"
                disabled={_.isEmpty(fanpageSelected) ? true : false}
                handleClickButton={handleClick}
              />
            </Flex>
          </Flex>
        </div>
      )}

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => openModalTag('add')}>
          {t('customer_management.add_bombot_tag.title')}
        </MenuItem>
        <MenuItem onClick={() => openModalTag('edit')}>
          {t('customer_management.remove_bombot_tag.title')}
        </MenuItem>
      </Menu>

      {rows.length > 0 && (
        <>
          <h4 className="mt-3">
            {t('customer_management.list.amount_customer')}:
            <span style={{ color: 'red' }}>
              {!isCheckedAllPage && isUseCheckedAllPage
                ? 0
                : totalNumber <= 0
                ? 0
                : totalNumber}
            </span>
            <span style={{ color: '#05AE34' }}>
              /{parseInt(data && data.totalElements)}
            </span>
          </h4>
        </>
      )}
      <div className={classes.root}>
        <TableContainer
          component={Paper}
          style={{
            boxShadow: 'none',
            position: 'relative',
          }}
        >
          <Table>
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              checkBoxSelectAll={checkBoxSelectAll}
              isEmptyCheckItem={isEmptyCheckItem}
            />
            <TableBody>
              {(rows || []).map((row, index) => {
                const isItemSelected = isSelected(row.name)
                const labelId = `enhanced-table-checkbox-${index}`
                return (
                  <TableRow
                    key={row.uid}
                    className={[classes.heightHeader, classes.rowItem]}
                  >
                    <TableCell
                      align="center"
                      className={[classes.p0, classes.witdhCheckBox]}
                    >
                      <FormControlLabel
                        className={[classes.p0, classes.checkBoxPadding]}
                        control={
                          <Checkbox
                            className={classes.p0}
                            checked={row.checked}
                            inputProps={{ 'aria-labelledby': labelId }}
                            onChange={() => handleSelectCheckBox(row)}
                          />
                        }
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[classes.witdhSeq, classes.p0]}
                    >
                      {data?.number * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[classes.witdhUid, classes.p0]}
                    >
                      {row.uid ? (
                        <div>{row.uid}</div>
                      ) : (
                        <p
                          style={{
                            fontStyle: 'italic',
                            fontSize: '12px',
                          }}
                        >
                          {'_'}
                        </p>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[classes.widthName, classes.p0]}
                    >
                      {row.name ? (
                        <div>{row.name}</div>
                      ) : (
                        <p
                          style={{
                            fontStyle: 'italic',
                            fontSize: '12px',
                          }}
                        >
                          {'_'}
                        </p>
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: '86px',
                      }}
                      align="center"
                      className={[classes.widthGender, classes.p0]}
                    >
                      {row.gender ? (
                        <div>
                          {_.upperCase(row.gender) ==
                          _.upperCase(types.GENDER.MALE)
                            ? t('update.gender.male')
                            : _.upperCase(row.gender) ==
                              _.upperCase(types.GENDER.FEMALE)
                            ? t('update.gender.female')
                            : t('update.gender.other')}
                        </div>
                      ) : (
                        <p
                          style={{
                            fontStyle: 'italic',
                            fontSize: '12px',
                          }}
                        >
                          {'_'}
                        </p>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[classes.widthDate, classes.p0]}
                    >
                      {row.lastInboxTime ? (
                        <div>
                          {moment(row.lastInboxTime).format('DD/MM/YYYY HH:mm')}
                        </div>
                      ) : (
                        <p
                          style={{
                            fontStyle: 'italic',
                            fontSize: '12px',
                          }}
                        >
                          {'_'}
                        </p>
                      )}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthDate, classes.p0]}
                    >
                      {row.lastCustomerInboxTime ? (
                        <div>
                          {moment(row.lastCustomerInboxTime).format(
                            'DD/MM/YYYY HH:mm'
                          )}
                        </div>
                      ) : (
                        <p
                          style={{
                            fontStyle: 'italic',
                            fontSize: '12px',
                          }}
                        >
                          {'_'}
                        </p>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[classes.widthPhone, classes.p0]}
                    >
                      {row.phone ? (
                        <div>{row.phone}</div>
                      ) : (
                        <p
                          style={{
                            fontStyle: 'italic',
                            fontSize: '12px',
                          }}
                        >
                          {'_'}
                        </p>
                      )}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthTag, classes.p0]}
                    >
                      {/* {(() => {
                        const filteredTags = (row.fbTags || []).filter((item) =>
                          _.find(
                            (fanpageSelected && fanpageSelected.fbTags) || [],
                            { id: item }
                          )
                        )

                        //CONSOLE HERE
                        console.log('filteredTags',filteredTags)

                        return (
                          <div
                            className={[
                              classes.tagsContainer,
                              classes.widthTag,
                              classes.p0,
                            ].join(' ')}
                          >
                            {filteredTags.map((item, idx) => {
                              let obj = _.find(
                                (fanpageSelected && fanpageSelected.fbTags) ||
                                  [],
                                function (obj) {
                                  return obj.id === item
                                }
                              )
                              if (!_.isEmpty(obj) && parseInt(idx) < 3) {
                                const colorCode = getRandomTag(colorDir1)

                                return (
                                  <TagPreviewItem
                                    styleText={{
                                      maxWidth: '140px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                    key={index}
                                    background={
                                      obj?.backgroundColor || `#${obj?.color}`
                                    }
                                    color={obj?.textColor || '#FFF'}
                                    name={obj.name}
                                    size="small"
                                  />
                                )
                              }
                            })}
                            {filteredTags.length > 3 && !showModalAllTags && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation()

                                  setSeletedTags(filteredTags || null)
                                }}
                                className={classes.buttonLoadMore}
                              >
                                Xem tất cả ({(row.fbTags || []).length -1}) tags
                              </button>
                            )}
                          </div>
                        )
                      })()} */}
                      {renderTagsCell(row?.fbTags, 'fbTag', row)}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthTag, classes.p0]}
                    >
                      {/* {(() => {
                        // Filter the tags to include only those present in fanpageSelected.bombotTags
                        const filteredTags = (row.bombotTags || []).filter(
                          (item) =>
                            _.find(
                              (fanpageSelected && fanpageSelected.bombotTags) ||
                                [],
                              { id: item }
                            )
                        )

                        return (
                          <div
                            className={[
                              classes.tagsContainer,
                              classes.widthTag,
                              classes.p0,
                            ].join(' ')}
                          >
                            {filteredTags.map((item, idx) => {
                              let obj = _.find(
                                (fanpageSelected &&
                                  fanpageSelected.bombotTags) ||
                                  [],
                                function (obj) {
                                  return obj.id === item
                                }
                              )
                              if (!_.isEmpty(obj) && parseInt(idx) < 3) {
                                const colorCode = getRandomTag(colorDir1)

                                return (
                                  <TagPreviewItem
                                    styleText={{
                                      maxWidth: '140px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                    key={index}
                                    background={
                                      obj?.backgroundColor || `#${obj?.color}`
                                    }
                                    color={obj?.textColor || '#FFF'}
                                    name={obj.name}
                                    size="small"
                                  />
                                )
                              }
                            })}
                            {filteredTags.length > 3 && !showModalAllTags && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setSeletedTags(filteredTags || null)
                                }}
                                className={classes.buttonLoadMore}
                              >
                                Xem tất cả ({(row.bombotTags || []).length -1})
                                tags
                              </button>
                            )}
                          </div>
                        )
                      })()} */}
                      {renderTagsCell(row?.bombotTags, 'bombotTag', row)}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={[classes.widthDate, classes.p0]}
                    >
                      {row.lastSyncTime ? (
                        <div>
                          {moment(row.lastSyncTime).format('DD/MM/YYYY HH:mm')}
                        </div>
                      ) : (
                        <p
                          style={{
                            fontStyle: 'italic',
                            fontSize: '12px',
                          }}
                        >
                          {'_'}
                        </p>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={[classes.witdhAction, classes.p0]}
                    >
                      <Flex align="center" justify="center">
                        <span
                          className={classes.icon}
                          onClick={() => handleDetail(row)}
                        >
                          <ErrorOutlineIcon />
                        </span>
                        <span
                          className={cn(classes.icon, 'ml-2')}
                          onClick={() => handleEdit(row)}
                        >
                          <EditIcon />
                        </span>
                      </Flex>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {rows.length == 0 && (
          <p className="py-4 text-muted text-center">{t('update.no_data')}</p>
        )}
        {isPagination && rows.length > 0 && (
          <Flex
            style={{
              paddingBottom: '24px',
            }}
            className="w-100 px-3"
            align="center"
            justify="space-between"
          >
            <TablePagination
              rowsPerPageOptions={[types.PAGINATION.SIZE, 20, 30, 50]}
              component="div"
              count={parseInt(data && data.totalElements)}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={t('update.display')}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              className={'pagination'}
            />
            <Pagination
              count={Math.ceil((data && data.totalElements) / rowsPerPage)}
              page={page == 0 ? page + 1 : page}
              onChange={handleChange}
            />
          </Flex>
        )}
        {/* {rows.length > 0 && (
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Thay đổi khoảng cách hiển thị gần"
          />
        )} */}

        <div className={classes.buttonIEA}>
          <ButtonComponent
            id="import_customer"
            className={cn(classes.buttonImport, 'btn--rounder', 'btn--primary')}
            title={
              <Flex align="center">
                <ExitToAppIcon />
                <span className="ml-1">{t('common.btn.import_customer')}</span>
              </Flex>
            }
            backgroundColor="#0c0b0b"
            color="white"
            handleClickButton={() => handleSubmitImport()}
            disabled={_.isEmpty(fanpageSelected) ? true : false}
          />
          <ButtonComponent
            id="export_customer"
            className={cn(classes.buttonExport, 'btn--rounder', 'btn--primary')}
            title={
              <Flex align="center">
                <GetAppIcon />
                <span className="ml-1">{t('common.btn.export_customer')}</span>
              </Flex>
            }
            backgroundColor="#FF8000"
            color="white"
            handleClickButton={() => handleSubmitExport()}
            disabled={_.isEmpty(fanpageSelected) ? true : false}
          />
          {/* <ButtonComponent
            className={classes.buttonAdd}
            title={"Tạo chiến dịch"}
            backgroundColor="#05AE34"
            color="white"
            handleClickButton={() => handleOpenExtention()}
            disabled={_.isEmpty(fanpageSelected) ? true : false}
          /> */}
          <ButtonComponent
            id="create_campaign"
            className={cn(
              classes.buttonAdd,
              'btn--rounder',
              'btn--primary--filled'
            )}
            title={
              <Flex align="center">
                <AddCircleIcon />
                <span className="ml-1">
                  {t('customer_management.create_campaign.title')}
                </span>
              </Flex>
            }
            backgroundColor="#05AE34"
            color="white"
            handleClickButton={() => handleSubmitCreate()}
            disabled={_.isEmpty(fanpageSelected) ? true : false}
          />
          {typeModalTag && (
            <ModalTagsBombot
              isRemove={typeModalTag === 'edit'}
              handleClose={onCloseModal}
              setMessageType={setTypeMessage}
              customerAfterFilter={customerAfterFilter}
              customerAll={customerAll}
            />
          )}
          <FilterCustomerModal
            customerAll={customerAll}
            isOpen={isOpenModalFilter}
            handleClose={toggleModalFilter}
            onSubmitFilter={onSubmitFilter}
            searchVal={search}
          />
          {/* <ManageTagModal
            isOpen={isOpenModalManageTag}
            setIsOpen={setIsOpenModalManageTag}
            handleClose={toggleModalManageTag}
            onSubmitFilter={onSubmitFilter}
          /> */}
          {isOpenModalManageTag && (
            <TagsManagementModal
              isOpen={isOpenModalManageTag}
              handleClose={() => setIsOpenModalManageTag(false)}
              getData={() => {
                return getData({
                  page: page,
                  size: rowsPerPage,
                  search: `pageId==${fanpageSelected.id};${
                    search && `uid|name==` + search
                  }`,
                  sort: 'lastInboxTime,desc',
                })
              }}
              getFanpage={getFanpage}
            />
          )}
        </div>
      </div>

      {!_.isNull(selectedTags) && (
        <TagsModal
          onClose={() => setSeletedTags(null)}
          isOpen={!_.isNull(selectedTags)}
          selectedTags={selectedTags?.tagList || []}
          customer={selectedTags?.customer || null}
          type={selectedTags?.type}
          tagsObj={tagsObj}
          handleRemoveTags={handleRemoveTags}
        />
      )}
    </>
  )
}

EnhancedTable.propTypes = {
  data: PropTypes.array,
  search: PropTypes.string,
  isSearch: PropTypes.bool,
  isPagination: PropTypes.bool,
  getData: PropTypes.func,
  handleGetData: PropTypes.func,
}

EnhancedTable.defaultProps = {
  isPagination: true,
  isSearch: true,
  handleGetData: (f) => f,
  getData: (f) => f,
  data: [],
  search: null,
}
