import React, { useEffect } from 'react'
import { showError, showSuccess } from '../../stores/alert/slice.alert'
import firebase from 'firebase'
import { useDispatch, useSelector } from 'react-redux'
import { vapidKey } from '../../constants/firebase'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { pathKeys } from '../../constants'
import { accountApi } from '../../api/accountApi'
import { selectUser } from '../App/AppSelectors'

const NotiPermission = ({ children }) => {
  const messaging = firebase.messaging()
  const history = useHistory()

  const user = useSelector(selectUser)

  const dispatch = useDispatch()

  const requestForToken = () => {
    return messaging
      .getToken(messaging, {
        vapidKey: vapidKey,
      })
      .then((currentToken) => {
        return currentToken
      })
  }

  function getUserIdFromURL() {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    return params.get('userId')
  }

  useEffect(() => {
    ;(async () => {
      try {
        const userId = getUserIdFromURL() || user?.id

        if (!userId) return

        const notiToken = await requestForToken()

        const payload = {
          userId,
          token: notiToken,
        }

        const res = await accountApi.updateNotiPermissionToken(payload)

        dispatch(showSuccess('Get Notification Permision Successfully'))

        history.push(pathKeys.HOME)
      } catch (error) {
        dispatch(showError(error.message))
      }
    })()
  }, [user?.id])

  return <>{children}</>
}

export default NotiPermission
