// import { BehaviorSubject } from 'rxjs';
import axios from 'axios'
import axiosClient from './axiosClient'
import urlApi from './url'
import { pathKeys } from '../constants'

export const accountApi = {
  login,
  apiAuthenticate,
  onSubmitEmail,
  changePassword,
  logout,
  getInviteCode,
  transferToAccount,
  updateNotiPermissionToken,
}

function onSubmitEmail(params) {
  return new Promise((resolve, reject) => {
    const response = axiosClient.post(`${urlApi.user.login}`, params)
    if (response) {
      resolve(response)
    } else {
      reject(null)
    }
  })
}

function changePassword(params) {
  return new Promise((resolve, reject) => {
    const response = axiosClient.post(`${urlApi.user.changePassword}`, params)
    if (response) {
      resolve(response)
    } else {
      reject(null)
    }
  })
}

function getInviteCode(params) {
  return new Promise((resolve, reject) => {
    const response = axiosClient.get(`${urlApi.user.getInviteCode}`)
    if (response) {
      resolve(response)
    } else {
      reject(null)
    }
  })
}

function getMeFacebook() {
  return new Promise((resolve, reject) => {
    window.FB.api(
      '/me',
      {
        fields:
          'id,email,first_name,last_name,name,picture.width(200).height(200)',
      },
      function (response) {
        if (response) {
          resolve(response)
        } else {
          reject(null)
        }
      }
    )
  })
}

function authLoginFacebook() {
  // Hàm api.getUser() trả về một promise object
  return new Promise((resolve, reject) => {
    window.FB.login(
      function (response) {
        if (response) {
          resolve(response)
        } else {
          reject(null)
        }
      },
      {
        auth_type: 'rerequest',
        scope: 'email, public_profile,pages_show_list',
        enable_profile_selector: true,
        return_scopes: true,
      }
      // { scope: "email" }
    )
  })
}

function apiAuthenticate(authResponse, me, ref) {
  return new Promise((resolve, reject) => {
    const params = {
      id: authResponse.userID,
      name: me.name,
      email: me.email,
      avatar: me.picture.data.url,
      fbAccessToken: authResponse.accessToken,
    }
    if (ref) params.partnerId = ref
    const response = axios.post(`${urlApi.user.loginSocial}`, params)
    if (response) {
      resolve(response)
    } else {
      reject(null)
    }
  })
}

function login(ref) {
  return new Promise((resolve, reject) => {
    window.FB.getLoginStatus(function (response) {
      if (response.status === 'connected') {
        getMeFacebook().then((me) => {
          apiAuthenticate(response.authResponse, me, ref).then(
            (loginSocial) => {
              resolve({ ...loginSocial.data, email: me.email })
            }
          )
        })
      } else {
        authLoginFacebook().then((auth) => {
          if (auth.authResponse) {
            getMeFacebook().then((me) => {
              apiAuthenticate(auth.authResponse, me, ref).then(
                (loginSocial) => {
                  resolve({ ...loginSocial.data, email: me.email })
                }
              )
            })
          } else {
            reject(null)
          }
        })
      }
    })
  })
}

function logout() {
  localStorage.removeItem('token')
  localStorage.removeItem('id')
  window.FB.getLoginStatus(function (response) {
    if (response && response.status === 'connected') {
      window.FB.logout(function (response) {
        window.location.href = `${pathKeys.LOGIN}`
      })
    } else if (response.status === 'unknown') {
      window.FB.logout(function (response) {})
      window.location.href = `${pathKeys.LOGIN}`
    }
    window.location.reload(true)
  })

  // window.FB.api('/me/permissions', 'delete', null, (res) => {
  //   if (res) {
  //     window.FB.logout();
  //   }
  //   window.location.href = `${pathKeys.LOGIN}`;
  // });
}

function transferToAccount(payload) {
  return new Promise((resolve, reject) => {
    const response = axiosClient.post(`/api/v1.0/user/changeMainUser`, payload)
    if (response) {
      resolve(response)
    } else {
      reject(null)
    }
  })
}

function updateNotiPermissionToken(payload) {
  return new Promise((resolve, reject) => {
    const response = axiosClient.post(
      `/api/v1.0/user/subscribeNotification`,
      payload
    )
    if (response) {
      resolve(response)
    } else {
      reject(null)
    }
  })
}
