import axiosClient from './axiosClient'
import urlApi from './url'
const fanpageApi = {
  get: (params) => {
    return axiosClient.get(`${urlApi.fanpageApi.reportPage}`, { params })
  },
  fanPageDetail: (params) => {
    return axiosClient.post(`${urlApi.fanpageApi.fanPageDetail}`, params)
  },
  getDetailFanpage: (id) => {
    return axiosClient.get(`/api/v1.0/fanPage/${id}`)
  },

  searchTags: (id, params) => {
    return axiosClient.get(`/api/v1.0/fanPage/${id}/fanPagebombotTags`, {
      params,
    })
  },

  getBomBotTagList: (id, params) => {
    return axiosClient.get(`/api/v1.0/fanPage/${id}/fanPagebombotTags`, {
      params,
    })
  },

  deleteBomBotTagList: (id, payload) => {
    return axiosClient.delete(`/api/v1.0/fanPage/${id}/fanPagebombotTags`, {
      data: payload,
    })
  },

  renameTag: (fanpageId, tagId, payload) => {
    return axiosClient.post(
      `/api/v1.0/fanPage/${fanpageId}/fanPagebombotTags/${tagId}`,
      payload
    )
  },
}

export default fanpageApi
