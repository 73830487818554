import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SettingAccountView from './SettingAccountView'
import * as selectorsApp from '../App/AppSelectors'
import * as actionsSettingAccount from './SettingAccountActions'
import * as selectorsHome from '../Home/HomeSelectors'
import * as selectorSettingAccount from './SettingAccountSelectors'
import * as actionsHome from '../Home/HomeActions'

const mapStateToProps = (state) => {
  return {
    user: selectorsApp.user(state),
    share: selectorsHome.share(state),
    changePassword: selectorSettingAccount.changePassword(state),
    whatYouNeed: selectorSettingAccount.whatYouNeed(state),
    inviteCode: selectorSettingAccount.inviteCode(state),
    unlink: selectorSettingAccount.unlink(state),
    messageUnlink: selectorSettingAccount.messageUnlink(state),
    messageInviteError: selectorSettingAccount.messageInviteError(state),
    loading: selectorsApp.loading(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data, uid) => dispatch(actionsHome.updateUser(data, uid)),
  onWhatYouNeed: (params) =>
    dispatch(actionsSettingAccount.getWhatYouNeed(params)),
  onSubmitChangePassword: (params) =>
    dispatch(actionsSettingAccount.onSubmitChangePassword(params)),
  onChangePasswordStatus: (params) =>
    dispatch(actionsSettingAccount.onChangePasswordStatus(params)),
  onGetInviteCode: () => dispatch(actionsSettingAccount.onGetInviteCode()),
  onUnlink: (data) => dispatch(actionsSettingAccount.onUnlink(data)),
  onGetUser: (data) => dispatch(actionsSettingAccount.onGetUser(data)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SettingAccountView)
)
