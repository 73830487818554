import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import './Root.css'
import App from './views/App'
import { BrowserRouter } from 'react-router-dom'
import GlobalStyles from './GlobalStyles'
import theme from './theme'
import {
  MuiThemeProvider,
  CssBaseline,
  responsiveFontSizes,
} from '@material-ui/core'
import firebase from 'firebase'
import NotiPermission from './views/NotiPermission'

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: 'AIzaSyBhGWBekJgaa-YtWTSj000lxDS6t0MPiZE',
    authDomain: 'bombot-296af.firebaseapp.com',
    projectId: 'bombot-296af',
    storageBucket: 'bombot-296af.appspot.com',
    messagingSenderId: '641430483635',
    appId: '1:641430483635:web:ba98b284f0464f9d2a36c6',
    measurementId: 'G-L07FB3BXTW',
  })
}
/**
 * @return {null}
 */

function Root(props) {
  const { store } = props
  const responsiveFSTheme = responsiveFontSizes(theme())

  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker
  //       .register('/firebase-messaging-sw.js')
  //       .then((registration) => {
  //         console.log(
  //           'Service Worker registered with scope:',
  //           registration.scope
  //         )
  //       })
  //       .catch((error) => {
  //         console.error('Service Worker registration failed:', error)
  //       })
  //   }
  // }, [])

  return (
    <Provider store={store}>
      <BrowserRouter>
        <MuiThemeProvider theme={responsiveFSTheme}>
          <CssBaseline />
          <GlobalStyles />
          {/* <NotiPermission> */}
          <App />
          {/* </NotiPermission> */}
        </MuiThemeProvider>
      </BrowserRouter>
    </Provider>
  )
}

export default Root
