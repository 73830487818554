export default {
  LOGIN: '/dang-nhap',
  HOME: '/trang-chu',
  FANPAGE: '/quan-ly-fan-page',
  CUSTOMER: '/quan-ly-khach-hang',
  CAMPAIGN: '/quan-ly-chien-dich',
  SETTING_ACCOUNT: '/quan-ly-tai-khoan',
  INVITE: '/invite-code',
  NOTI_PERMISSION: '/noti-permisson',
}
